import { memo } from 'react';
import { Link } from 'react-router-dom';

import ScrollContainer from '../../UI/ScrollContainer';
// import CustomButton from "../../CustomButton";
import imgLogo from '../../../assets/img/rkf-logo-new.svg';
import AuthSlider from './AuthSlider';

import './index.scss';

const AuthLayout = ({ children }) => {
    // const history = useHistory();
    // const isLoginPage = history.location.pathname === '/login';

    return (
        <ScrollContainer
            className="auth"
        >
            <div className="auth-layout__content-wrap">
                <div className="auth-layout__toolbar">
                    <Link to="/" className="auth-layout__logo">
                        <img src={imgLogo} alt="" className="auth-layout__logo-img"/>
                        <span className="auth-layout__logo-text">РКФ Офис</span>
                    </Link>
                    {/*<div className="auth-layout__toolbar-right">*/}
                    {/*    <span className="auth-layout__toolbar-text">*/}
                    {/*        {isLoginPage ?*/}
                    {/*            'Создать учетную запись?' :*/}
                    {/*            'Есть учетная запись?'*/}
                    {/*        }*/}
                    {/*    </span>*/}
                    {/*    <CustomButton*/}
                    {/*        bgColor="white"*/}
                    {/*        onClick={() => history.push(isLoginPage ? '/registration' : '/login')}*/}
                    {/*    >*/}
                    {/*        {isLoginPage ? 'Регистрация' : 'Вход'}*/}
                    {/*    </CustomButton>*/}
                    {/*</div>*/}
                </div>
                <div className="auth-layout__content">
                    <div className="auth-layout__left">
                        {children}
                    </div>
                    <div className="auth-layout__right">
                        <AuthSlider/>
                    </div>
                </div>
            </div>
        </ScrollContainer>
    );
};

export default memo(AuthLayout);
