import Slider from 'react-slick';

import slideOne from '../../../../assets/img/AuthSlider/1.png';
import slideTwo from '../../../../assets/img/AuthSlider/2.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.scss';

const slides = [
    {
        img: slideOne,
        title: 'Слайд 1',
        text: 'Описание для первого слайда',
    },
    {
        img: slideTwo,
        title: 'Слайд 2',
        text: 'Описание для второго слайда',
    },
];

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    swipe: true,
    touchMove: true,
    swipeToSlide: true,
    draggable: true,
};

const AuthSlider = () => (
    <div className="auth-slider">
        <Slider {...settings}>
            {slides.map((slide, i) => (
                <div key={i} className="auth-slider__slide">
                    <img
                        src={slide.img}
                        alt=""
                        className="auth-slider__slide-img"
                    />
                    {/* <div className="auth-slider__slide-info">
                        <h5>{slide.title}</h5>
                        <p>{slide.text}</p>
                    </div> */}
                </div>
            ))}
        </Slider>
    </div>
);

export default AuthSlider;
