import { memo, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { IconButton, Badge } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CropFreeIcon from '@mui/icons-material/CropFree';
import screenfull from 'screenfull';
import { MenuContext } from '../../../../context/MenuContext';

import LightTooltip from '../../../LigthTooltip';
import UserInfo from './UserInfo';

import './index.scss';

const Header = () => {
    const { isOpen, toggleMenu } = useContext(MenuContext);
    const { pathname } = useLocation();

    const headerTitle = useMemo(() => {
        if (pathname.includes('/federation/')) return 'Федерация';
        else if (pathname.includes('/cynological-department/')) return 'Кинологический департамент РКФ';
        else if (pathname.includes('/accounting/')) return 'Бухгалтерия РКФ';
        else if (pathname.includes('/secretariat/')) return 'Секретариат РКФ';
        else if (pathname.includes('/veterinarian/')) return 'Ветеринарный врач';
        else if (pathname.includes('/production-department/')) return 'Производственный департамент РКФ';
        else return '';
    }, [pathname]);

    return (
        <header className="user-layout__header header">
            <div className="header__left">
                <LightTooltip title={isOpen ? 'Закрыть меню' : 'Открыть меню'}>
                    <IconButton mini="true" onClick={() => toggleMenu(!isOpen)}>
                        <MenuIcon/>
                    </IconButton>
                </LightTooltip>
                {headerTitle && <p className="header__title">{headerTitle}</p>}
            </div>
            <div className="header__right">
                <LightTooltip title="Уведомления">
                    <IconButton mini="true" onClick={() => null}>
                        <Badge badgeContent={0} max={99} color="error" overlap="rectangular">
                            <NotificationsActiveIcon/>
                        </Badge>
                    </IconButton>
                </LightTooltip>
                <LightTooltip title="Полноэкранный режим">
                    <IconButton mini="true" onClick={() => screenfull.toggle()}>
                        <CropFreeIcon/>
                    </IconButton>
                </LightTooltip>
                <UserInfo/>
            </div>
        </header>
    );
};

export default memo(Header);
