const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
};

const handleResponse = (res) => {
    return res.ok
        ? res.json()
        : Promise.reject(`Произошла ошибка: ${res.status}`);
};

export const updateTokenData = async (refreshToken) => {
    return fetch(`/api/v1/refresh_token?refresh_token=${refreshToken}`, {
        method: 'POST',
        headers: {
            ...headers,
        },
    })
        .then(handleResponse);
};
