import { TDogData } from './types';

export const formInitialValues = (): TDogData => {
    return {
        name: '',
        name_en: '',
        date_birth: '',
        breed_id: null,
        sex_type_id: null,
        wool_color_id: null,
        kennel_id: null,
        kennel_name: '',
        pedigree_rkf: {
            id: null,
            value: '',
            date_registration: '',
            is_current: false,
            register_id: null,
        },
        pedigree_foreign: [
            {
                id: null,
                value: '',
                date_registration: '',
                is_current: false,
            },
        ],
        stamp_code_id: null,
        stamp_number: '',
        current_chip_value: '',
        exterior_mark_id: null,
        father: {
            id: null,
            name: '',
        },
        mother: {
            id: null,
            name: '',
        },
        owner: {
            name: '',
            address: '',
            address_en: '',
        },
        breeder: {
            name_en: '',
            address: '',
            address_en: '',
            combinedName: '',
        },
        additional_info: {
            comment: '',
            folder_number: '',
        },
        last_note: {
            id: null,
            date_create: '',
            note: '',
            profile_name: '',
        },
    };
};
