import { useSelector } from 'react-redux';

import AccessDenied from '../Layouts/AccessDenied';

import { isAccessDenied } from '../../utils/access';
import { selectUserActions } from '../../redux/selectors/mainSelectors';

const ProtectedRoute = ({ component: Component, isProtected, protectAction }) => {
    const userActions = useSelector(selectUserActions);

    return isProtected && isAccessDenied(userActions, protectAction)
        ? <AccessDenied />
        : <Component protectAction={protectAction} />;
};

export default ProtectedRoute;
