import { useCallback, useEffect, useState } from 'react';
import useIsMobile from '../hooks/useIsMobile';

export const useToggleMenu = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [dogId, setDogId] = useState(0);
    const isMobile = useIsMobile(991);

    const toggleMenu = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (!isMobile) {
            setIsOpen(true);
        }
    }, [isMobile]);

    return { isOpen, toggleMenu, dogId, setDogId };
};
