import * as Sentry from '@sentry/react';

import { Provider } from 'react-redux';

import { createRoot } from 'react-dom/client';

import App from './App';

import { store } from './redux';

import { dsn } from './sentryConfig';

import './index.scss';

Sentry.init({
    dsn: dsn[window.location.hostname],
});

// Отправляем тестовую ошибку
// Sentry.captureException(new Error('Тестовая ошибка для проверки Sentry'));

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <Provider store={store}>
        <App/>
    </Provider>,
);
