import { createAsyncThunk } from '@reduxjs/toolkit';

import { getHeaders } from '../../../../hooks/request.hook';

export const getUserActions = createAsyncThunk(
    'user/getUserActions',
    async (
        _, // первый аргумент - параметры, которые мы можем передать
        { rejectWithValue }, // второй аргумент - набор вспомогательных функций из API (например, getState, rejectWithValue и т.д.)
    ) => {
        let accessToken = localStorage.getItem('token')
            ? JSON.parse(localStorage.getItem('token')!)
            : '';

        const response = await fetch('/api/v1/users/user_actions', {
            headers: getHeaders(accessToken),
        });

        const result = await response.json();

        if (!result?.length) {
            return rejectWithValue('Что-то пошло не так ...');
        }

        return result;
    },
    {
        // Тут описываются условия, при выполнении которых отработает запрос выше.
        // Если условия не будут выполнены, то запроса не будет.
        condition: (
            _, // первый аргумент - параметры, которые мы можем передать
            { getState }, // второй аргумент - набор вспомогательных функций из API (например, getState, rejectWithValue и т.д.)
        ) => {
            // Если есть права, то не надо ничего перезапрашивать
            return !(getState() as any).user.actions;
        },
    },
);
