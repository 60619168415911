import { memo, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuItem, ListItemIcon, ListItemText, Avatar, IconButton } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { AuthContext } from '../../../../../context/AuthContext';

import { DEFAULT_AVATAR } from '../../../../../appConfig';

import './index.scss';

const StyledMenu = (props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
);

const UserInfo = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { user, logout } = useContext(AuthContext);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="header__user-info user-info">
            <IconButton mini="true"  className="user-info__btn" onClick={handleClick}>
                <Avatar src={user.avatar_link || DEFAULT_AVATAR} alt="" className="user-info__avatar"/>
            </IconButton>
            <StyledMenu
                id="user-info"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
            >
                <li className="user-info__menu-head">
                    <p>{user.name || 'Пользователь 1'}</p>
                    <p>{user.email || 'info@example.com'}</p>
                </li>
                <MenuItem>
                    <Link to="/profile" className="user-info__menu-link">
                        <ListItemIcon>
                            <PersonIcon style={{ color: '#5D92F4' }} />
                        </ListItemIcon>
                        <ListItemText primary="Личный кабинет" />
                    </Link>
                </MenuItem>
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <PowerSettingsNewIcon style={{ color: '#FF3739' }} />
                    </ListItemIcon>
                    <ListItemText primary="Выйти" />
                </MenuItem>
            </StyledMenu>
        </div>
    );
};

export default memo(UserInfo);
