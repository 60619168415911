import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import accessDenitedIcon from '../../../assets/img/access-denited-icon.png';

import './index.scss';

const AccessDenied = () => {
    const navigate = useNavigate();
    const [seconds, setSeconds] = useState(9);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSeconds((seconds) => seconds - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (seconds <= 0) {
            navigate(-1);
        }
    }, [seconds, navigate]);

    return (
        <div className="access-denited">
            <h1>Доступ запрещен!</h1>
            <p>У Вас нет прав для просмотра данной страницы.</p>
            {seconds > 0 && <p>Вы будете автоматически перенаправлены через <span>{seconds}</span> секунд.</p>}
            <img src={accessDenitedIcon} alt="Доступ запрещен"/>
        </div>
    );
};

export default memo(AccessDenied);
