import { createContext } from 'react';

function noop() {
}

export const MenuContext = createContext({
    isOpen: true,
    toggleMenu: noop,
    dogId: 0,
    setDogId: (id) => id,
});
