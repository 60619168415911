import * as Pages from './pages';

export const authRoutes = [
    {
        path: '/login',
        element: Pages.LoginPage,
    },
    {
        path: '/registration',
        element: Pages.UnderDevelopment, //RegistrationPage
    },
    {
        path: '/recovery',
        element: Pages.UnderDevelopment, //RecoveryPage
    },
];
