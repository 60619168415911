import { memo, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AuthLayout from '../../components/Layouts/AuthLayot';
import Loading from '../../components/Loading';

import { authRoutes } from './routes';

const Auth = () => (
    <AuthLayout>
        <Routes>
            {authRoutes.map((route) =>
                <Route
                    key={route.path}
                    path={route.path}
                    element={
                        <Suspense fallback={<Loading/>}>
                            <route.element/>
                        </Suspense>
                    }
                />,
            )}
            <Route
                path="*"
                element={<Navigate to="/login" replace />}
            />
        </Routes>
    </AuthLayout>
);

export default memo(Auth);
