import * as Pages from './pages';

export const userRoutes = [
    {
        path: '/',
        isProtected: false,
        element: Pages.MainPage,
    },
    {
        path: '/events',
        isProtected: false,
        element: Pages.UnderDevelopment,
    },
    {
        path: '/learning-center',
        isProtected: false,
        element: Pages.UnderDevelopment,
    },
    {
        path: '/profile',
        isProtected: false,
        element: Pages.ProfilePage,
    },
    {
        path: '/rkf-online',
        isProtected: false,
        element: Pages.UnderDevelopment,
    },

    //administrator
    {
        path: '/administrator',
        isProtected: false,
        element: Pages.AdministratorPage,
    },
    {
        path: '/administrator/achievements',
        isProtected: true,
        protectAction: 'ClubDetailedReport.GETALL',
        element: Pages.AdministratorAchievementsCard,
    },
    {
        path: '/administrator/breed-distribution',
        isProtected: true,
        protectAction: 'BreedDistribution.GETAll',
        element: Pages.AdministratorBreedSort,
    },
    {
        path: '/administrator/linking-profiles',
        isProtected: true,
        protectAction: 'IsSuperUser.GETALL',
        element: Pages.AdministratorLinkingProfiles,
    },
    {
        path: '/administrator/pedigree-data',
        isProtected: true,
        protectAction: 'TGUsers.GETAll',
        element: Pages.AdministratorPedigreeData,
    },
    {
        path: '/administrator/pedigree-data/:id',
        isProtected: true,
        protectAction: 'TGUsers.GET',
        element: Pages.AdministratorPedigreeDataCard,
    },
    {
        path: '/administrator/personal-information',
        isProtected: true,
        protectAction: 'PersDataReq.GETAll',
        element: Pages.AdministratorPersonalInformation,
    },
    {
        path: '/administrator/personal-information/:id',
        isProtected: true,
        protectAction: 'PersDataReq.GET',
        element: Pages.AdministratorPersonalInformationCard,
    },
    {
        path: '/administrator/profiles-management',
        element: Pages.AdministratorProfilesManagement,
        isProtected: true,
        protectAction: 'IsSuperUser.GETALL',
    },
    {
        path: '/administrator/profiles-management/:id',
        element: Pages.AdministratorProfilesManagementCard,
        isProtected: true,
        protectAction: 'IsSuperUser.GETALL',
    },
    {
        path: '/administrator/roles-management',
        element: Pages.AdministratorProfilesManagement, // TODO заменить, когда будут готовы страницы
        isProtected: true,
        protectAction: 'IsSuperUser.GETALL',
    },
    {
        path: '/administrator/access-management',
        element: Pages.AdministratorProfilesManagement, // TODO заменить, когда будут готовы страницы
        isProtected: true,
        protectAction: 'IsSuperUser.GETALL',
    },
    {
        path: '/administrator/exhibitions-mono',
        element: Pages.AdministratorExhibitionsMono,
        isProtected: true,
        protectAction: 'ExhibitionsMono.GETALL',
    },
    {
        path: '/administrator/exhibitions-mono/:id',
        element: Pages.AdministratorExhibitionsMonoCard,
        isProtected: true,
        protectAction: 'ExhibitionsMono.GET',
    },
    {
        path: '/administrator/education-proof',
        element: Pages.AdministratorEducationProof,
        isProtected: true,
        protectAction: 'EducationProof.GETALL',
    },
    {
        path: '/administrator/education-proof/:id',
        element: Pages.AdministratorEducationProofCard,
        isProtected: true,
        protectAction: 'EducationProof.GET',
    },

    //documents
    {
        path: '/documents',
        isProtected: false,
        element: Pages.DocumentsPage,
    },

    {
        path: '/documents/accounting/applications-registers',
        isProtected: true,
        protectAction: 'ClaimsRegisters.GETAll',
        element: Pages.DocumentsAccountingRegistry,
    },
    {
        path: '/documents/accounting/applications-registers/contribution_type_report',
        isProtected: true,
        protectAction: 'ClaimsRegisters.GETAll',
        element: Pages.DocumentsAccountingRegistryContribution,
    },
    {
        path: '/documents/accounting/applications-registers/:id',
        isProtected: true,
        protectAction: 'ClaimsRegisters.GETAll',
        element: Pages.DocumentsAccountingRegistryCard,
    },
    {
        path: '/documents/accounting/displasia',
        isProtected: true,
        protectAction: 'AccDysplasia.GETAll',
        element: Pages.DocumentsAccountingDisplasia,
    },
    {
        path: '/documents/accounting/displasia/:id',
        isProtected: true,
        protectAction: 'AccDysplasia.GET',
        element: Pages.DocumentsAccountingDisplasiaCard,
    },
    {
        path: '/documents/accounting/knee-joints',
        isProtected: true,
        protectAction: 'AccPatella.GETAll',
        element: Pages.DocumentsAccountingKneeJoints,
    },
    {
        path: '/documents/accounting/knee-joints/:id',
        isProtected: true,
        protectAction: 'AccPatella.GET',
        element: Pages.DocumentsAccountingKneeJointsCard,
    },
    {
        path: '/documents/accounting/price-list',
        isProtected: true,
        protectAction: 'AccPricelist.GETAll',
        element: Pages.DocumentsAccountingPriceList,
    },
    {
        path: '/documents/accounting/price-list/:id',
        isProtected: true,
        protectAction: 'AccPricelist.GET',
        element: Pages.DocumentsAccountingPriceListCard,
    },
    {
        path: '/documents/accounting/documents/diplomas',
        isProtected: true,
        protectAction: 'AccGetRKFDocs.GETAll',
        element: Pages.DocumentsAccountingDocumentsDiplomas,
    },
    {
        path: '/documents/accounting/documents/diplomas/:id',
        isProtected: true,
        protectAction: 'AccGetRKFDocs.GET',
        element: Pages.DocumentsAccountingDocumentsDiplomasCard,
    },
    {
        path: '/documents/accounting/documents/fci-titles',
        isProtected: true,
        protectAction: 'AccGetRKFDocs.GETAll',
        element: Pages.DocumentsAccountingDocumentsFciTitles,
    },
    {
        path: '/documents/accounting/documents/fci-titles/:id',
        isProtected: true,
        protectAction: 'AccGetRKFDocs.GET',
        element: Pages.DocumentsAccountingDocumentsFciTitlesCard,
    },
    {
        path: '/documents/accounting/documents/kerung',
        isProtected: true,
        protectAction: 'AccGetRKFDocs.GETAll',
        element: Pages.DocumentsAccountingDocumentsKerung,
    },
    {
        path: '/documents/accounting/documents/kerung/:id',
        isProtected: true,
        protectAction: 'AccGetRKFDocs.GET',
        element: Pages.DocumentsAccountingDocumentsKerungCard,
    },
    {
        path: '/documents/accounting/documents/pedigree-certificate',
        isProtected: true,
        protectAction: 'AccGetRKFDocs.GETAll',
        element: Pages.DocumentsAccountingDocumentsPedigreeCertificate,
    },
    {
        path: '/documents/accounting/documents/pedigree-certificate/:id',
        isProtected: true,
        protectAction: 'AccGetRKFDocs.GET',
        element: Pages.DocumentsAccountingDocumentsPedigreeCertificateCard,
    },
    {
        path: '/documents/accounting/documents/working-certificate',
        isProtected: true,
        protectAction: 'AccGetRKFDocs.GETAll',
        element: Pages.DocumentsAccountingDocumentsWorkingCertificate,
    },
    {
        path: '/documents/accounting/documents/working-certificate/:id',
        isProtected: true,
        protectAction: 'AccGetRKFDocs.GET',
        element: Pages.DocumentsAccountingDocumentsWorkingCertificateCard,
    },

    {
        path: '/documents/cynological-department/litter-registration',
        isProtected: true,
        protectAction: 'HandLitterReq.GETAll',
        element: Pages.DocumentsCynologicalLitterRegistration,
    },
    {
        path: '/documents/cynological-department/litter-registration/:id',
        isProtected: true,
        protectAction: 'HandLitterReq.GET',
        element: Pages.DocumentsCynologicalLitterRegistrationCard,
    },
    {
        path: '/documents/cynological-department/litter-registration/:id/registration',
        isProtected: true,
        protectAction: 'HandLitterReq.GET',
        element: Pages.DocumentsCynologicalLitterRegistrationForm,
    },
    {
        path: '/documents/cynological-department/pedigree-creating',
        isProtected: true,
        protectAction: 'HandPedigreeReq.GETAll',
        element: Pages.DocumentsCynologicalPedigreeCreating,
    },
    {
        path: '/documents/cynological-department/pedigree-creating/:id',
        isProtected: true,
        protectAction: 'HandPedigreeReq.GET',
        element: Pages.DocumentsCynologicalPedigreeCreatingCard,
    },
    {
        path: '/documents/cynological-department/pedigree-creating/:id/search-dog',
        isProtected: false,
        element: Pages.DocumentsCynologicalPedigreeCreatingSearchDog,
    },
    {
        path: '/documents/cynological-department/pedigree-creating/:id/search-owner',
        isProtected: false,
        element: Pages.DocumentsCynologicalPedigreeCreatingSearchOwner,
    },
    {
        path: '/documents/cynological-department/pedigree-replacement',
        isProtected: true,
        protectAction: 'HandReplace.GETAll',
        element: Pages.DocumentsCynologicalPedigreeReplacement,
    },
    {
        path: '/documents/cynological-department/pedigree-replacement/:id',
        isProtected: true,
        protectAction: 'HandReplace.GET',
        element: Pages.DocumentsCynologicalPedigreeReplacementCard,
    },
    {
        path: '/documents/cynological-department/pedigree-replacement/:id/search-dog',
        isProtected: false,
        element: Pages.DocumentsCynologicalPedigreeCreatingSearchDog,
    },
    {
        path: '/documents/cynological-department/pedigree-replacement/:id/search-owner',
        isProtected: false,
        element: Pages.DocumentsCynologicalPedigreeCreatingSearchOwner,
    },
    {
        path: '/documents/cynological-department/attaching-dogs',
        isProtected: true,
        protectAction: 'AttachingDog.GETAll',
        element: Pages.DocumentsCynologicalAttachingDogsTable,
    },
    {
        path: '/documents/cynological-department/attaching-dogs/:id',
        isProtected: true,
        protectAction: 'AttachingDog.GETAll',
        element: Pages.DocumentsCynologicalAttachingDogs,
    },

    {
        path: '/documents/federation/clubs-detailed-report',
        isProtected: true,
        protectAction: 'ClaimsRegisters.GETAll',
        element: Pages.DocumentsFederationClubsDetailedReport,
    },
    {
        path: '/documents/federation/clubs-detailed-report/report',
        isProtected: true,
        protectAction: 'ClaimsRegisters.GETAll',
        element: Pages.DocumentsFederationClubsDetailedReportReport,
    },
    {
        path: '/documents/federation/litter-registration',
        isProtected: true,
        protectAction: 'FedLitterPack.GETAll',
        element: Pages.DocumentsFederationLitterRegistration,
    },
    {
        path: '/documents/federation/litter-registration/:id',
        isProtected: true,
        protectAction: 'FedLitterPack.GET',
        element: Pages.DocumentsFederationLitterRegistrationCard,
    },
    {
        path: '/documents/federation/litter-registration/:id/:requestId',
        isProtected: true,
        protectAction: 'FedLitterReq.GET',
        element: Pages.DocumentsFederationLitterRegistrationOneCard,
    },
    {
        path: '/documents/federation/pedigree-creating',
        isProtected: true,
        protectAction: 'FedPedigreePack.GETAll',
        element: Pages.DocumentsFederationPedigreeCreating,
    },
    {
        path: '/documents/federation/pedigree-creating/:id',
        isProtected: true,
        protectAction: 'FedPedigreePack.GET',
        element: Pages.DocumentsFederationPedigreeCreatingCard,
    },
    {
        path: '/documents/federation/pedigree-creating/:id/:requestId',
        isProtected: true,
        protectAction: 'FedPedigreeReq.GET',
        element: Pages.DocumentsFederationPedigreeCreatingOneCard,
    },
    {
        path: '/documents/federation/pedigree-replacement',
        isProtected: true,
        protectAction: 'FedReplace.GETAll',
        element: Pages.DocumentsFederationPedigreeReplacement,
    },
    {
        path: '/documents/federation/pedigree-replacement/:id',
        isProtected: true,
        protectAction: 'FedReplace.GET',
        element: Pages.DocumentsFederationPedigreeReplacementCard,
    },

    {
        path: '/documents/print-workspace/printing',
        isProtected: true,
        protectAction: 'PrintDocuments.GETAll',
        element: Pages.DocumentsPrintingWorkspaceCard,
    },
    {
        path: '/documents/print-workspace/reports',
        isProtected: true,
        protectAction: 'PrintDocuments.GETAll',
        element: Pages.DocumentsPrintReportsCard,
    },

    {
        path: '/documents/production-department/displasia',
        isProtected: true,
        protectAction: 'ProdDepDysplasia.GETAll',
        element: Pages.DocumentsProductionDepartmentDisplasia,
    },
    {
        path: '/documents/production-department/displasia/:id',
        isProtected: true,
        protectAction: 'ProdDepDysplasia.GET',
        element: Pages.DocumentsProductionDepartmentDisplasiaCard,
    },
    {
        path: '/documents/production-department/exhibitions',
        isProtected: false,
        element: Pages.DocumentsProductionDepartmentExhibitions,
    },
    {
        path: '/documents/production-department/exhibitions/:id',
        isProtected: false,
        element: Pages.DocumentsProductionDepartmentExhibitionsCard,
    },
    {
        path: '/documents/production-department/knee-joints',
        isProtected: true,
        protectAction: 'ProdDepPatella.GETAll',
        element: Pages.DocumentsProductionDepartmentKneeJoints,
    },
    {
        path: '/documents/production-department/knee-joints/:id',
        isProtected: true,
        protectAction: 'ProdDepPatella.GET',
        element: Pages.DocumentsProductionDepartmentKneeJointsCard,
    },
    {
        path: '/documents/production-department/documents/diplomas',
        isProtected: true,
        protectAction: 'ProdDepGetRKFDocs.GETAll',
        element: Pages.DocumentsProductionDepartmentDocumentsDiplomas,
    },
    {
        path: '/documents/production-department/documents/diplomas/:id',
        isProtected: true,
        protectAction: 'ProdDepGetRKFDocs.GET',
        element: Pages.DocumentsProductionDepartmentDocumentsDiplomasCard,
    },
    {
        path: '/documents/production-department/documents/fci-titles',
        isProtected: true,
        protectAction: 'ProdDepGetRKFDocs.GETAll',
        element: Pages.DocumentsProductionDepartmentDocumentsFciTitles,
    },
    {
        path: '/documents/production-department/documents/fci-titles/:id',
        isProtected: true,
        protectAction: 'ProdDepGetRKFDocs.GET',
        element: Pages.DocumentsProductionDepartmentDocumentsFciTitlesCard,
    },
    {
        path: '/documents/production-department/documents/kerung',
        isProtected: true,
        protectAction: 'ProdDepGetRKFDocs.GETAll',
        element: Pages.DocumentsProductionDepartmentDocumentsKerung,
    },
    {
        path: '/documents/production-department/documents/kerung/:id',
        isProtected: true,
        protectAction: 'ProdDepGetRKFDocs.GET',
        element: Pages.DocumentsProductionDepartmentDocumentsKerungCard,
    },
    {
        path: '/documents/production-department/documents/pedigree-certificate',
        isProtected: true,
        protectAction: 'ProdDepGetRKFDocs.GETAll',
        element: Pages.DocumentsProductionDepartmentDocumentsPedigreeCertificate,
    },
    {
        path: '/documents/production-department/documents/pedigree-certificate/:id',
        isProtected: true,
        protectAction: 'ProdDepGetRKFDocs.GET',
        element: Pages.DocumentsProductionDepartmentDocumentsPedigreeCertificateCard,
    },
    {
        path: '/documents/production-department/documents/working-certificate',
        isProtected: true,
        protectAction: 'ProdDepGetRKFDocs.GETAll',
        element: Pages.DocumentsProductionDepartmentDocumentsWorkingCertificate,
    },
    {
        path: '/documents/production-department/documents/working-certificate/:id',
        isProtected: true,
        protectAction: 'ProdDepGetRKFDocs.GET',
        element: Pages.DocumentsProductionDepartmentDocumentsWorkingCertificateCard,
    },

    {
        path: '/documents/secretariat/achievements',
        isProtected: true,
        protectAction: 'AttributIssuance.GETALL',
        element: Pages.DocumentsSecretariatAchievements,
    },
    {
        path: '/documents/secretariat/displasia',
        isProtected: true,
        protectAction: 'SecDysplasia.GETAll',
        element: Pages.DocumentsSecretariatDisplasia,
    },
    {
        path: '/documents/secretariat/displasia/:id',
        isProtected: true,
        protectAction: 'SecDysplasia.GET',
        element: Pages.DocumentsSecretariatDisplasiaCard,
    },
    {
        path: '/documents/secretariat/exhibitions',
        isProtected: true,
        protectAction: 'SecExhibitionRequests.GETAll',
        element: Pages.DocumentsSecretariatExhibitions,
    },
    {
        path: '/documents/secretariat/exhibitions/:id',
        isProtected: true,
        protectAction: 'SecExhibitionRequests.GET',
        element: Pages.DocumentsSecretariatExhibitionsCard,
    },
    {
        path: '/documents/secretariat/knee-joints',
        isProtected: true,
        protectAction: 'SecPatella.GETAll',
        element: Pages.DocumentsSecretariatKneeJoints,
    },
    {
        path: '/documents/secretariat/knee-joints/:id',
        isProtected: true,
        protectAction: 'SecPatella.GET',
        element: Pages.DocumentsSecretariatKneeJointsCard,
    },
    {
        path: '/documents/secretariat/documents/diplomas',
        isProtected: true,
        protectAction: 'SecGetRKFDocs.GETAll',
        element: Pages.DocumentsSecretariatDocumentsDiplomas,
    },
    {
        path: '/documents/secretariat/documents/diplomas/:id',
        isProtected: true,
        protectAction: 'SecGetRKFDocs.GET',
        element: Pages.DocumentsSecretariatDocumentsDiplomasCard,
    },
    {
        path: '/documents/secretariat/documents/fci-titles',
        isProtected: true,
        protectAction: 'SecGetRKFDocs.GETAll',
        element: Pages.DocumentsSecretariatDocumentsFciTitles,
    },
    {
        path: '/documents/secretariat/documents/fci-titles/:id',
        isProtected: true,
        protectAction: 'SecGetRKFDocs.GET',
        element: Pages.DocumentsSecretariatDocumentsFciTitlesCard,
    },
    {
        path: '/documents/secretariat/documents/kerung',
        isProtected: true,
        protectAction: 'SecGetRKFDocs.GETAll',
        element: Pages.DocumentsSecretariatDocumentsKerung,
    },
    {
        path: '/documents/secretariat/documents/kerung/:id',
        isProtected: true,
        protectAction: 'SecGetRKFDocs.GET',
        element: Pages.DocumentsSecretariatDocumentsKerungCard,
    },
    {
        path: '/documents/secretariat/documents/pedigree-certificate',
        isProtected: true,
        protectAction: 'SecGetRKFDocs.GETAll',
        element: Pages.DocumentsSecretariatDocumentsPedigreeCertificate,
    },
    {
        path: '/documents/secretariat/documents/pedigree-certificate/:id',
        isProtected: true,
        protectAction: 'SecGetRKFDocs.GET',
        element: Pages.DocumentsSecretariatDocumentsPedigreeCertificateCard,
    },
    {
        path: '/documents/secretariat/documents/working-certificate',
        isProtected: true,
        protectAction: 'SecGetRKFDocs.GETAll',
        element: Pages.DocumentsSecretariatDocumentsWorkingCertificate,
    },
    {
        path: '/documents/secretariat/documents/working-certificate/:id',
        isProtected: true,
        protectAction: 'SecGetRKFDocs.GET',
        element: Pages.DocumentsSecretariatDocumentsWorkingCertificateCard,
    },

    {
        path: '/documents/veterinarian/displasia',
        isProtected: true,
        protectAction: 'VetDysplasia.GETAll',
        element: Pages.DocumentsVeterinarianDisplasia,
    },
    {
        path: '/documents/veterinarian/displasia/:id',
        isProtected: true,
        protectAction: 'VetDysplasia.GET',
        element: Pages.DocumentsVeterinarianDisplasiaCard,
    },

    {
        path: '/documents/:service',
        isProtected: false,
        element: Pages.DocumentsPage,
    },
    {
        path: '/documents/:service/documents',
        isProtected: false,
        element: Pages.DocumentsPage,
    },

    //dogs
    {
        path: '/dogs',
        isProtected: false,
        element: Pages.DogsPage,
    },
    {
        path: '/dogs/create-dog',
        isProtected: false,
        element: Pages.DogsCreateDog,
    },
    {
        path: '/dogs/:id',
        isProtected: false,
        element: Pages.DogsDog,
    },
    {
        path: '/dogs/:id/applications',
        isProtected: false,
        element: Pages.DogsDogApplications,
    },
    {
        path: '/dogs/:id/documents',
        isProtected: false,
        element: Pages.DogsDogDocuments,
    },
    // TODO удалить, если страница не будет использоваться
    // {
    //     path: '/dogs/:id/dysplasia',
    //     isProtected: false,
    //     element: Pages.DogsDogDysplasia,
    // },
    {
        path: '/dogs/:id/information',
        isProtected: false,
        element: Pages.DogsDogInformation,
    },
    {
        path: '/dogs/:id/littermates',
        isProtected: false,
        element: Pages.DogsDogLittermates,
    },
    {
        path: '/dogs/:id/litters',
        isProtected: false,
        element: Pages.DogsDogLitters,
    },
    {
        path: '/dogs/:id/pedigree',
        isProtected: false,
        element: Pages.DogsDogPedigree,
    },
    {
        path: '/dogs/:id/sanctions',
        isProtected: false,
        element: Pages.DogsDogSanctions,
    },

    //references
    {
        path: '/references',
        isProtected: false,
        element: Pages.ReferencesPage,
    },

    {
        path: '/references/breeds-classifier',
        isProtected: true,
        protectAction: 'Breeds.GETAll',
        element: Pages.ReferencesBreedsClassifier,
    },
    {
        path: '/references/breeds-classifier/add',
        isProtected: true,
        protectAction: 'Breeds.POST',
        element: Pages.ReferencesBreedsClassifierCreateCard,
    },
    {
        path: '/references/breeds-classifier/:id',
        isProtected: true,
        protectAction: 'Breeds.GET',
        element: Pages.ReferencesBreedsClassifierCard,
    },
    {
        path: '/references/breeds-classifier/:id/edit',
        isProtected: true,
        protectAction: 'Breeds.PUT',
        element: Pages.ReferencesBreedsClassifierCreateCard,
    },

    {
        path: '/references/breeds-groups-fci',
        isProtected: true,
        protectAction: 'FCIGroups.GETAll',
        element: Pages.ReferencesBreedsGroupsFCI,
    },
    {
        path: '/references/breeds-groups-fci/add',
        isProtected: true,
        protectAction: 'FCIGroups.POST',
        element: Pages.ReferencesBreedsGroupsFCICreateCard,
    },
    {
        path: '/references/breeds-groups-fci/:id',
        isProtected: true,
        protectAction: 'FCIGroups.GET',
        element: Pages.ReferencesBreedsGroupsFCICard,
    },
    {
        path: '/references/breeds-groups-fci/:id/edit',
        isProtected: true,
        protectAction: 'FCIGroups.PUT',
        element: Pages.ReferencesBreedsGroupsFCICreateCard,
    },

    {
        path: '/references/breeds-sections-fci',
        isProtected: true,
        protectAction: 'FCISections.GETAll',
        element: Pages.ReferencesBreedsSectionsFCI,
    },
    {
        path: '/references/breeds-sections-fci/add',
        isProtected: true,
        protectAction: 'FCISections.POST',
        element: Pages.ReferencesBreedsSectionsFCICreateCard,
    },
    {
        path: '/references/breeds-sections-fci/:id',
        isProtected: true,
        protectAction: 'FCISections.GET',
        element: Pages.ReferencesBreedsSectionsFCICard,
    },
    {
        path: '/references/breeds-sections-fci/:id/edit',
        isProtected: true,
        protectAction: 'FCISections.PUT',
        element: Pages.ReferencesBreedsSectionsFCICreateCard,
    },

    {
        path: '/references/clubs',
        isProtected: true,
        protectAction: 'Clubs.GETAll',
        element: Pages.ReferencesClubs,
    },
    {
        path: '/references/clubs/:id',
        isProtected: true,
        protectAction: 'Clubs.GET',
        element: Pages.ReferencesClubsCard,
    },
    {
        path: '/references/clubs/:id/edit',
        isProtected: true,
        protectAction: 'Clubs.PUT',
        element: Pages.ReferencesClubsCreateCard,
    },

    {
        path: '/references/federal-districts',
        isProtected: true,
        protectAction: 'FederalDistricts.GETAll',
        element: Pages.ReferencesFederalDistricts,
    },
    {
        path: '/references/federal-districts/:id',
        isProtected: true,
        protectAction: 'FederalDistricts.GET',
        element: Pages.ReferencesFederalDistrictsCard,
    },

    {
        path: '/references/federations',
        isProtected: true,
        protectAction: 'Federations.GETAll',
        element: Pages.ReferencesFederations,
    },
    {
        path: '/references/federations/:id',
        isProtected: true,
        protectAction: 'Federations.GET',
        element: Pages.ReferencesFederationsCard,
    },
    {
        path: '/references/federations/:id/edit',
        isProtected: true,
        protectAction: 'Federations.PUT',
        element: Pages.ReferencesFederationsCreateCard,
    },

    {
        path: '/references/kennels',
        isProtected: true,
        protectAction: 'Nurseries.GETAll',
        element: Pages.ReferencesKennels,
    },
    {
        path: '/references/kennels/:id',
        isProtected: true,
        protectAction: 'Nurseries.GET',
        element: Pages.ReferencesKennelsCard,
    },
    {
        path: '/references/kennels/:id/edit',
        isProtected: true,
        protectAction: 'Nurseries.PUT',
        element: Pages.ReferencesKennelsCreateCard,
    },

    {
        path: '/references/localities',
        isProtected: true,
        protectAction: 'Cities.GETAll',
        element: Pages.ReferencesLocalities,
    },
    {
        path: '/references/localities/add',
        isProtected: true,
        protectAction: 'Cities.POST',
        element: Pages.ReferencesLocalitiesCreateCard,
    },
    {
        path: '/references/localities/:id',
        isProtected: true,
        protectAction: 'Cities.GET',
        element: Pages.ReferencesLocalitiesCard,
    },
    {
        path: '/references/localities/:id/edit',
        isProtected: true,
        protectAction: 'Cities.PUT',
        element: Pages.ReferencesLocalitiesCreateCard,
    },

    {
        path: '/references/localities-types',
        isProtected: true,
        protectAction: 'CityTypes.GETAll',
        element: Pages.ReferencesLocalitiesTypes,
    },
    {
        path: '/references/localities-types/add',
        isProtected: true,
        protectAction: 'CityTypes.POST',
        element: Pages.ReferencesLocalitiesTypesCreateCard,
    },
    {
        path: '/references/localities-types/:id',
        isProtected: true,
        protectAction: 'CityTypes.GET',
        element: Pages.ReferencesLocalitiesTypesCard,
    },
    {
        path: '/references/localities-types/:id/edit',
        isProtected: true,
        protectAction: 'CityTypes.PUT',
        element: Pages.ReferencesLocalitiesTypesCreateCard,
    },

    {
        path: '/references/national-breed-clubs',
        isProtected: true,
        protectAction: 'NBC.GETAll',
        element: Pages.ReferencesNBC,
    },
    {
        path: '/references/national-breed-clubs/add',
        isProtected: true,
        protectAction: 'NBC.POST',
        element: Pages.ReferencesNBCCreateCard,
    },
    {
        path: '/references/national-breed-clubs/:id',
        isProtected: true,
        protectAction: 'NBC.GET',
        element: Pages.ReferencesNBCCard,
    },
    {
        path: '/references/national-breed-clubs/:id/edit',
        isProtected: true,
        protectAction: 'NBC.PUT',
        element: Pages.ReferencesNBCCreateCard,
    },

    {
        path: '/references/regions',
        isProtected: true,
        protectAction: 'Regions.GETAll',
        element: Pages.ReferencesRegions,
    },
    {
        path: '/references/regions/:id',
        isProtected: true,
        protectAction: 'Regions.GET',
        element: Pages.ReferencesRegionsCard,
    },
];
