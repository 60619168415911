import moment from 'moment';
import 'moment/locale/ru';
import { useRequestQuery } from './useRequestQuery.hook';

import type { TChip, TDogLog, TDogNote, TPedigree } from '../pages/User/Dogs/Dog/Modals/types';
import type { TLitterMates } from '../pages/User/Dogs/Littermates/types';
import type { TDog } from '../pages/User/Dogs/Information/types';
import type { TBreedOption, TDogData, TKennelOption, TNavigationCounter } from '../pages/User/Dogs/Dog/types';
import type { TLitters, TLittersDetail, TSelectedRow } from '../pages/User/Dogs/Litters/types';
import type { SanctionsData } from '../pages/User/Dogs/Sanctions/types';
import type { TDogAncestorsData } from '../pages/User/Dogs/Pedigree/types';

import { formInitialValues } from '../pages/User/Dogs/Dog/config';
import { DOG_GET, type TValueList } from '../constants/requests';
import { TIME } from '../constants/time';

moment.locale('ru');

// Получаем общие данные по собаке
export const useGetDogData = (dogId?: string) => {
    return useRequestQuery<TDogData>(
        {
            url: `/api/v1/dogs/${dogId}`,
        },
        {
            enabled: !!dogId,
            queryKey: [DOG_GET.GET_DOG_DATA, dogId],
            placeholderData: formInitialValues,
            select: (data) => {
                const modifyData = (data: TDogData) => {
                    const father = data.father;
                    const mother = data.mother;
                    const breeder = data.breeder;

                    return {
                        father: {
                            id: data.father.id || null,
                            name: data.father.id
                                ? `${
                                    father.name} / ${
                                    father.name_en} ${
                                    father.pedigree_history_value} ${
                                    father.date_create_exterior_mark_history
                                        ? moment(father.date_create_exterior_mark_history).format('DD.MM.YYYY')
                                        : ''} ${
                                    father.exterior_mark_short_name} ${
                                    father.wool_color_name
                                }`
                                : '',
                        },
                        mother: {
                            id: data.mother.id || null,
                            name: data.mother.id
                                ? `${
                                    mother.name} / ${
                                    mother.name_en} ${
                                    mother.pedigree_history_value} ${
                                    mother.date_create_exterior_mark_history
                                        ? moment(mother.date_create_exterior_mark_history).format('DD.MM.YYYY')
                                        : ''} ${
                                    mother.exterior_mark_short_name} ${
                                    mother.wool_color_name
                                }`
                                : '',
                        },
                        breeder: {
                            ...data.breeder,
                            combinedName: data.mother.id
                                ? `${breeder.name}${breeder.name && breeder.name_en
                                    ? ' / ' + breeder.name_en
                                    : breeder.name_en
                                        ? breeder.name_en
                                        : ''}`
                                : '',
                            address: breeder.address
                                ? breeder.address.replaceAll(', ', ',').split(',').join(', ')
                                : '',
                        },
                    };
                };

                return { ...data, ...modifyData(data) };
            },
            gcTime: 0,
            staleTime: 0,
        },
    );
};

// Получаем оптионсы селектора питомника
export const useGetKennelList = (searchString: string) => {
    return useRequestQuery<TKennelOption[]>(
        {
            url: '/api/v1/documents/dog_handler/litter_requests/kennels/valuelist',
            params: {
                name: searchString,
                skip: 0,
                is_deleted: false,
            },
        },
        {
            queryKey: [DOG_GET.GET_KENNEL_LIST, searchString],
            enabled: searchString?.length > 2,
            select: (data) => !!data?.length
                ? data.map((item: TKennelOption) => ({
                    label: item.name,
                    name: item.name,
                    value: item.value,
                    status_id: item.status_id,
                }))
                : [],
            initialData: [{
                label: '',
                name: '',
                value: 0,
                status_id: 0,
            }],
        },
    );
};

// Получаем оптионсы селектора окраса породы
export const useGetWoolColorList = (breed_id: string) => {
    return useRequestQuery<TValueList>(
        {
            url: `/api/v1/breeds/${breed_id}/wool_colors/valuelist`,
        },
        {
            queryKey: [DOG_GET.GET_WOOL_COLORS_LIST, breed_id],
            enabled: !!breed_id,
            gcTime: 0,
            staleTime: 0,
        },
    );
};

// Получаем оптионсы селектора (совместимых) пород
export const useGetBreedList = (breed_id?: string) => {
    const queryParams = {
        url: `/api/v1/breeds/compatible/valuelist${breed_id ? '?breed_id=' + breed_id : ''}`,
    };

    const queryConfig = {
        queryKey: [DOG_GET.GET_BREED_LIST, breed_id || 'all'],
        gcTime: TIME.ONE_HOUR,
        staleTime: TIME.ONE_HOUR,
    };

    return useRequestQuery<TBreedOption[]>(queryParams, queryConfig);
};

// Получаем оптионсы селектора пола собаки
export const useGetSexTypeList = () => {
    return useRequestQuery<TValueList>(
        {
            url: '/api/v1/dogs_sex_type/valuelist',
        },
        {
            queryKey: [DOG_GET.GET_SEX_TYPE_LIST],
            gcTime: TIME.ONE_HOUR,
            staleTime: TIME.ONE_HOUR,
        },
    );
};

// Получаем оптионсы селектора регистра
export const useGetRegisterList = () => {
    return useRequestQuery<TValueList>(
        {
            url: '/api/v1/pedigree_registers/valuelist',
        },
        {
            queryKey: [DOG_GET.GET_REGISTER_LIST],
            gcTime: TIME.ONE_HOUR,
            staleTime: TIME.ONE_HOUR,
        },
    );
};

// Получаем оптионсы селектора кода клейма
export const useGetStampCodeList = () => {
    return useRequestQuery<TValueList>(
        {
            url: '/api/v1/stamp_codes/valuelist',
        },
        {
            queryKey: [DOG_GET.GET_STAMP_CODE_LIST],
            gcTime: TIME.ONE_HOUR,
            staleTime: TIME.ONE_HOUR,
        },
    );
};

// Получаем краткие данные по собаке
export const useGetDogShortData = (dogId?: string) => {
    return useRequestQuery<TDog>(
        {
            url: `/api/v1/dogs/${dogId}/short_data`,
        },
        {
            queryKey: [DOG_GET.GET_DOG_SHORT_DATA, dogId],
            enabled: !!dogId,
            gcTime: 0,
            staleTime: 0,
        },
    );
};

// Получаем навигационный счетчик для собаки
export const useGetNavigationCounter = (dogId: string) => {
    return useRequestQuery<TNavigationCounter>(
        {
            url: `/api/v1/dogs/${dogId}/navigation_counter`,
        },
        {
            queryKey: [DOG_GET.GET_NAVIGATION_COUNTER, dogId],
            enabled: !!dogId,
            gcTime: TIME.FIVE_MINUTES,
            staleTime: TIME.FIVE_MINUTES,
        },
    );
};

// Получаем данные по помёту собаки
export const useGetLittersData = (dogId?: string) => {
    return useRequestQuery<TLitters[]>(
        {
            url: `/api/v1/dogs/${dogId}/litters`,
        },
        {
            queryKey: [DOG_GET.GET_LITTERS_DATA, dogId],
            enabled: !!dogId,
            gcTime: 0,
            staleTime: 0,
        },
    );
};

//Получение данных по однопометникам
export const useGetLittermatesData = (dogId?: string) => {
    return useRequestQuery<TLitterMates[]>(
        {
            url: `/api/v1/dogs/${dogId}/littermates`,
        },
        {
            queryKey: [DOG_GET.GET_LITTERMATES_DATA, dogId],
            enabled: !!dogId,
            gcTime: 0,
            staleTime: 0,
        },
    );
};

// Получаем детальные данные по помету собаки
export const useGetLitterByParentsData = (selectedRow: TSelectedRow | null) => {
    return useRequestQuery<TLittersDetail[]>(
        {
            url: '/api/v1/dogs/litter-by-parents/',
            params: {
                parent1: selectedRow?.id,
                parent2: selectedRow?.partner_id,
                date_birth: selectedRow?.date_birth || null,
            },
        },
        {
            queryKey: [DOG_GET.GET_LITTERS_BY_PARENTS_DATA, selectedRow?.id, selectedRow?.partner_id, selectedRow?.date_birth],
            enabled: !!selectedRow?.id && !!selectedRow?.partner_id,
            gcTime: TIME.TEN_MINUTES,
            staleTime: TIME.TEN_MINUTES,
        },
    );
};

// Получаем данные по санкциям собаки
export const useGetSanctionsData = (dogId?: string) => {
    return useRequestQuery<SanctionsData[]>(
        {
            url: '/api/v1/dogs/everk_dog_sanctions_dog_id',
            params: {
                dog_id: dogId,
            },
        },
        {
            queryKey: [DOG_GET.GET_SANCTIONS_DATA, dogId],
            enabled: !!dogId,
            gcTime: 0,
            staleTime: 0,
        },
    );
};

// Получаем данные о предках собаки
export const useGetAncestorsData = (dogId?: string) => {
    return useRequestQuery<TDogAncestorsData>(
        {
            url: `/api/v1/dogs/${dogId}/ancestors`,
        },
        {
            queryKey: [DOG_GET.GET_DOG_ANCESTORS_DATA, dogId],
            enabled: !!dogId,
            gcTime: 0,
            staleTime: 0,
        },
    );
};

// Получаем данные по примечаниям о собаке
export const useGetDogNotes = (dogId?: string) => {
    return useRequestQuery<TDogNote[]>(
        {
            url: `/api/v1/dogs/${dogId}/notes_histories`,
        },
        {
            queryKey: [DOG_GET.GET_DOG_NOTES_DATA, dogId],
            enabled: !!dogId,
            gcTime: 0,
            staleTime: 0,
        },
    );
};

// Получаем логи по собаке
export const useGetDogLogs = (dogId?: string) => {
    return useRequestQuery<TDogLog[]>(
        {
            url: `/api/v1/dogs/${dogId}/dogs_logs`,
        },
        {
            queryKey: [DOG_GET.GET_DOG_LOG_DATA, dogId],
            enabled: !!dogId,
            gcTime: TIME.ONE_MINUTE,
            staleTime: TIME.ONE_MINUTE,
        },
    );
};

// Получаем чипы по собаке
export const useGetDogChip = (dogId?: string) => {
    return useRequestQuery<TChip[]>(
        {
            url: `/api/v1/dogs/${dogId}/chips_histories`,
        },
        {
            queryKey: [DOG_GET.GET_DOG_CHIP, dogId],
            enabled: !!dogId,
            gcTime: TIME.ONE_MINUTE,
            staleTime: TIME.ONE_MINUTE,
        },
    );
};

// Получаем  данные по истории иностранных родословных
export const useGetDogPedigreeHistories = (dogId: string) => {
    return useRequestQuery<TPedigree[]>(
        {
            url: `/api/v1/dogs/${dogId}/foreign_pedigrees_histories`,
        },
        {
            queryKey: [DOG_GET.GET_DOG_PEDIGREE_HISTORIES, dogId],
            enabled: !!dogId,
            gcTime: TIME.ONE_MINUTE,
            staleTime: TIME.ONE_MINUTE,
        },
    );
};
