import { createContext } from 'react';

function noop() {}

export const AuthContext = createContext({
    token: null,
    user: null,
    login: noop,
    logout: noop,
    getUpdatedToken: (token) => token,
});
