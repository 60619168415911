import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { RequestOptions, useRequest } from './request.hook';

export const useRequestQuery = <T>(
    queryParams: RequestOptions,
    queryConfig: UseQueryOptions<T, Error, T, QueryKey>,
): UseQueryResult<T, Error> => {
    const { request } = useRequest();

    return useQuery<T>(
        {
            ...queryConfig,
            queryFn: () => request(queryParams),
        },
    );
};
