import { Scrollbar } from 'react-scrollbars-custom';

import './index.scss';

const ScrollContainer = ({ className, children, ...props }) => (
    <Scrollbar
        className={`scroll-container${className ? ' ' + className : ''}`}
        removeTracksWhenNotUsed
        {...props}
    >
        {children}
    </Scrollbar>
);

export default ScrollContainer;
