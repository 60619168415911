export type TValueList = {
    name: string;
    value: number;
}[];

export enum DOG_GET {
    GET_DOG_DATA = 'GET_DOG_DATA', // Получаем общие данные по собаке, url: /api/v1/dogs/${dogId}
    GET_DOG_SHORT_DATA = 'GET_DOG_SHORT_DATA', // Получаем краткие данные по собаке, url: /api/v1/dogs/${dogId}/short_data
    GET_KENNEL_LIST = 'GET_KENNEL_LIST', // Получаем оптионсы селектора питомника, url: /api/v1/documents/dog_handler/litter_requests/kennels/valuelist
    GET_WOOL_COLORS_LIST = 'GET_WOOL_COLORS_LIST', // Получаем оптионсы селектора окраса породы, url /api/v1/breeds/${breed_id}/wool_colors/valuelist
    GET_BREED_LIST = 'GET_BREED_LIST', // Получаем оптионсы селектора (совместимых) пород, url: /api/v1/breeds/compatible/valuelist
    GET_SEX_TYPE_LIST = 'GET_SEX_TYPE_LIST', // Получаем оптионсы селектора пола собаки, url: /api/v1/dogs_sex_type/valuelist
    GET_REGISTER_LIST = 'GET_REGISTER_LIST', // Получаем оптионсы селектора регистра, url: /api/v1/pedigree_registers/valuelist
    GET_STAMP_CODE_LIST = 'GET_STAMP_CODE_LIST', // Получаем оптионсы селектора кода клейма, url: /api/v1/stamp_codes/valuelist
    GET_NAVIGATION_COUNTER = 'GET_NAVIGATION_COUNTER', // Получаем навигационный счетчик для собаки, url: /api/v1/dogs/${dogId}/navigation_counter
    GET_LITTERS_DATA = 'GET_LITTERS_DATA', // Получаем общие данные по помёту собаки, url: /api/v1/dogs/${dogId}/litters
    GET_LITTERMATES_DATA = 'GET_LITTERMATES_DATA', // Получаем общие данные по однопометникам, url: /api/v1/dogs/${dogId}/littermates
    GET_LITTERS_BY_PARENTS_DATA = 'GET_LITTERS_BY_PARENTS_DATA', // Получаем детальные данные по помету собаки, url: /api/v1/dogs/litter-by-parents/
    GET_SANCTIONS_DATA = 'GET_SANCTIONS_DATA', // Получаем данные по санкциям собаки, url: /api/v1/dogs/everk_dog_sanctions_dog_id
    GET_DOG_NOTES_DATA = 'GET_DOG_NOTES_DATA', // Получаем данные по примечаниям о собаке, url: /api/v1/dogs/${dogId}/notes_histories
    GET_DOG_ANCESTORS_DATA = 'GET_DOG_ANCESTORS_DATA', // Получаем данные о предках собаки, url: /api/v1/dogs/${dogId}/ancestors
    GET_DOG_LOG_DATA = 'GET_DOG_LOG_DATA', // Получаем логи по собаке, url: /api/v1/dogs/${dogId}/dogs_logs
    GET_DOG_CHIP ='GET_DOG_CHIP', // Получаем  данные по чипам собаки, url: /api/v1/dogs/${dogId}/chips_histories
    GET_DOG_PEDIGREE_HISTORIES ='GET_DOG_PEDIGREE_HISTORIES', // Получаем  данные по истории родословной, url: /api/v1/dogs/${dogId}/pedigrees_histories
}

export enum GENERAL_GET {
    GET_COUNTRIES_LIST = 'GET_COUNTRIES_LIST', // Получаем список стран, url: /api/v1/countries/
    GET_FEDERATIONS_LIST = 'GET_FEDERATIONS_LIST', // Получаем список федераций, url: /api/v1/federations/valuelist
}

export enum ADMIN_GET {
    GET_USER_LIST = 'GET_USER_LIST', // Получаем список пользователей, url: /api/v1/administrator/profiles_register/
    GET_USER_PROFILE = 'GET_USER_PROFILE', // Получаем профль, url: /api/v1/administrator/profiles_register/${profileId}/
    GET_USER_ROLES_LIST = 'GET_USER_ROLES_LIST', // Получаем роли, url: /api/v1/administrator/profiles_register/roles
    GET_ATTRIBUTE_REPORT = 'GET_ATTRIBUTE_REPORT', // Получаем сводные данные по аттрибутике, url: /api/v1/documents/federations/clubs_detailed_report/detailing
}

export enum ACCOUNTING_GET {
    GET_DETAILING = 'GET_DETAILING', // Получаем сводные данные по взнгосам, url: /api/v1/documents/accounting/register_of_reports/${id}/detailing/
    GET_CREATE_DETAILING = 'GET_CREATE_DETAILING', // Создаем сводные данные по взнгосам, url: /api/v1/documents/accounting/register_of_reports/contribution_type_report/detailing
}

export enum SECRETARIAT_GET {
    GET_SUMMERY_ATTRIBUTE_REPORT = 'GET_SUMMERY_ATTRIBUTE_REPORT', // Получаем сводные данные по наградной аттрибутике, url: /api/v1/documents/federations/issuance_of_attribute_report/detailing/}
}

export enum OWNER_GET {
    GET_OWNER_INFO = 'GET_OWNER_INFO', // Получаем данные о владельце из Онлайн, url: `/api/v1/documents/dog_handler/pedigree_requests/${id}/owner_info`
    GET_CURRENT_OWNER_INFO = 'GET_CURRENT_OWNER_INFO', // Получаем данные о текущем владельце, url: `/api/v1/documents/dog_handler/pedigree_requests/current_owner/${ownerId}`
}

export enum FEDERATIONS_GET {
    GET_SUMMERY_CLUB_DETAILING_REPORT = 'GET_SUMMERY_CLUB_DETAILING_REPORT',
    GET_LAST_GENERATED_DATE = 'GET_LAST_GENERATED_DATE',
}

export enum CYNOLOGIC_GET {
    GET_CYNOLOGISTS_LIST = 'GET_CYNOLOGISTS_LIST', // Получаем список кинологов, url: /api/v1/administrator/cynologists/
}

export enum DOG_HANDLER_GET {
    GET_DOG_HANDLER_REQUEST = 'GET_DOG_HANDLER_REQUEST', // Получаем заявку на прикрепление собаки, url: /api/v1/documents/dog_handler/attaching_dogs_requests/{attaching_dogs_request_id}
}

export enum MODAL_GET {
    GET_MODAL_IMAGE = 'GET_MODAL_IMAGE', // Получаем картинку для модалки по ссылке, которую передали
}

export enum EXHIBITION_GET {
    GET_EXHIBITION = 'GET_EXHIBITION', // Получаем данные по выставке, url: /api/v1/administrator/exhibitions_mono/{exhibition_id}
    GET_EXHIBITION_JUDGES = 'GET_EXHIBITION_JUDGES', // Получаем список судей по выставке, url: /api/v1/administrator/exhibitions_mono/{exhibition_id}/judges
}

export enum EDUCATION_PROOF_GET {
    GET_EDUCATION_PROOF = 'GET_EDUCATION_PROOF', // Получаем данные по заявки на подтверждение кинологического образования у Актировщика, url: /api/v1/administrator/education_proof_requests/{education_proof_request_id}
}
