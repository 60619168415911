import defaultAvatar from './assets/img/default-avatar.svg';

export const DEFAULT_AVATAR = defaultAvatar;

export const MOMENT_LOCALE_DEFINITION = {
    week: {
        dow: 1,
        doy: 1,
    },
    months : {
        format: ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'],
        standalone: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    },
    weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
};

export const AG_GRID_LOCALE_RU = {
    // Set Filter
    selectAll: 'Выделить всё',
    selectAllSearchResults: 'Выделить результат поиска',
    searchOoo: 'Поиск...',
    blanks: 'Пусто',
    noMatches: 'Нет совпадений',

    // Number Filter & Text Filter
    filterOoo: 'Фильтр...',
    equals: 'Равно',
    notEqual: 'Не равно',
    empty: 'Выберите',
    blank: 'Нет значения',
    notBlank: 'Значение есть',
    after: 'Начиная с',
    before: 'До',
    // Number Filter
    lessThan: 'Меньше',
    greaterThan: 'Больше',
    lessThanOrEqual: 'Меньше или равно',
    greaterThanOrEqual: 'Больше или равно',
    inRange: 'В промежутке',
    inRangeStart: 'От',
    inRangeEnd: 'До',

    // Text Filter
    contains: 'Содержит',
    notContains: 'Не содержит',
    startsWith: 'Начинается с',
    endsWith: 'Заканчивается',

    // Date Filter
    dateFormatOoo: 'гггг-мм-дд',

    // Filter Conditions
    andCondition: 'И',
    orCondition: 'ИЛИ',

    // Filter Buttons
    applyFilter: 'Применить',
    resetFilter: 'Сброс',
    clearFilter: 'Очистить',
    cancelFilter: 'Отменить',

    // Filter Titles
    textFilter: 'Текстовый фильтр',
    numberFilter: 'Фильтр чисел',
    dateFilter: 'Фильтр по дате',
    setFilter: 'Установить фильтр',

    // Side Bar
    columns: 'Столбцы',
    filters: 'Фильтры',

    // columns tool panel
    pivotMode: 'Режим поворота',
    groups: 'Группы строк',
    rowGroupColumnsEmptyMessage: 'Перетащите сюда для группировки по строкам',
    values: 'Значения',
    valueColumnsEmptyMessage: 'Перетащите сюда, чтобы объединить',
    pivots: 'Метки столбцов',
    pivotColumnsEmptyMessage: 'Перетащите сюда, чтобы задать метки столбцов',

    // Header of the Default Group Column
    group: 'Группа',

    // Other
    loadingOoo: 'Загрузка...',
    noRowsToShow: 'Нет данных',
    enabled: 'Включено',

    // Menu
    pinColumn: 'Закрепить колонку',
    pinLeft: 'Закрепить слева',
    pinRight: 'Закрепить справа',
    noPin: 'Не закреплять',
    valueAggregation: 'Агрегация по значению',
    autosizeThiscolumn: 'Автоматически задавать размер этой колонки',
    autosizeAllColumns: 'Автоматически задавать размер всем колонкам',
    groupBy: 'Группировать по',
    ungroupBy: 'Разгруппировать по',
    resetColumns: 'Сбросить столбцы',
    expandAll: 'Развернуть всё',
    collapseAll: 'Свернуть всё',
    copy: 'Копировать',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Копировать с заголовком',
    paste: 'Вставить',
    ctrlV: 'Ctrl+V',
    export: 'Экспорт',
    csvExport: 'Экспорт в CSV',
    excelExport: 'Экспорт в Excel (.xlsx)',
    excelXmlExport: 'Экспорт в XML (.xml)',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Сумма',
    min: 'Минимум',
    max: 'Максимум',
    none: 'Пусто',
    count: 'Количество',
    avg: 'Среднее значение',
    filteredRows: 'Отфильтровано',
    selectedRows: 'Выбрано',
    totalRows: 'Всего строк',
    totalAndFilteredRows: 'Строки',
    more: 'ещё',
    to: 'к',
    of: 'из',
    page: 'Страница',
    nextPage: 'Следующая страница',
    lastPage: 'Последняя страница',
    firstPage: 'Первая страница',
    previousPage: 'Предыдущая страница',

    // Pivoting
    pivotColumnGroupTotals: 'Всего',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Сводная диаграмма & режим сведения',
    pivotChart: 'Сводная диаграмма',
    chartRange: 'Диапазон диаграммы',

    columnChart: 'Столбиковая диаграмма',
    groupedColumn: 'Сгруппированная',
    stackedColumn: 'Сложенная',
    normalizedColumn: '100% Сложенная',

    barChart: 'Панель',
    groupedBar: 'Сгруппированная',
    stackedBar: 'Сложенная',
    normalizedBar: '100% Сложенная',

    pieChart: 'Круговая диаграмма',
    pie: 'Круг',
    doughnut: 'Кольцевая диаграмма',

    line: 'Линия',

    xyChart: 'X Y (Разброс)',
    scatter: 'Диаграмма рассеяния',
    bubble: 'Пузырьковая диаграмма',

    areaChart: 'Область',
    area: 'Диаграмма с областями',
    stackedArea: 'Сложенная',
    normalizedArea: '100% Сложенная',

    histogramChart: 'Гистограмма',

    // Charts
    pivotChartTitle: 'Сводная диаграмма',
    rangeChartTitle: 'График диапазона',
    settings: 'Настройки',
    data: 'Данные',
    format: 'Формат',
    categories: 'Категории',
    defaultCategory: '(Пусто)',
    series: 'Серии',
    xyValues: 'X Y Значения',
    paired: 'Парный режим',
    axis: 'Ось',
    navigator: 'Навигация',
    color: 'Цвет',
    thickness: 'Толщина',
    xType: 'X Тип',
    automatic: 'Автоматически',
    category: 'Категория',
    number: 'Число',
    time: 'Время',
    xRotation: 'X Поворот',
    yRotation: 'Y Поворот',
    ticks: 'Отметки',
    width: 'Ширина',
    height: 'Высота',
    length: 'Длина',
    padding: 'Внутренний отступ',
    spacing: 'Отступ',
    chart: 'Диаграмма',
    title: 'Заголовок',
    titlePlaceholder: 'Заголовок диаграммы - двойной щелчок для редактирования',
    background: 'Фон',
    font: 'Шрифт',
    top: 'Верх',
    right: 'Право',
    bottom: 'Низ',
    left: 'Лево',
    labels: 'Метки',
    size: 'Размер',
    minSize: 'Минимальный размер',
    maxSize: 'Максимальный размер',
    legend: 'Легенда',
    position: 'Позиция',
    markerSize: 'Размер маркера',
    markerStroke: 'Обводка маркера',
    markerPadding: 'Внутренний отступ маркера',
    itemSpacing: 'Расстояние между предметами',
    itemPaddingX: 'Внутренний отступ предмета по X',
    itemPaddingY: 'Внутренний отступ предмета по Y',
    layoutHorizontalSpacing: 'Горизонтальный отступ',
    layoutVerticalSpacing: 'Вертикальный отступ',
    strokeWidth: 'Ширина обводки',
    offset: 'Смещение',
    offsets: 'Смещения',
    tooltips: 'Всплывающие подсказки',
    callout: 'Вызов',
    markers: 'Маркеры',
    shadow: 'Тень',
    blur: 'Размытие',
    xOffset: 'Смещение по X',
    yOffset: 'Смещение по Y',
    lineWidth: 'Ширина линии',
    normal: 'Нормальный',
    bold: 'Жирный',
    italic: 'Наклоненный',
    boldItalic: 'Жирный наклоненный',
    predefined: 'Предопределенный',
    fillOpacity: 'Непрозрачность заливки',
    strokeOpacity: 'Непрозрачность линии',
    histogramBinCount: 'Количество сегментов',
    columnGroup: 'Столбец',
    barGroup: 'Панель',
    pieGroup: 'Круговая',
    lineGroup: 'Линейная',
    scatterGroup: 'X Y (Разброс)',
    areaGroup: 'Зональная',
    histogramGroup: 'Гистограмма',
    groupedColumnTooltip: 'Сгруппированная',
    stackedColumnTooltip: 'Сложенная',
    normalizedColumnTooltip: '100% Сложенная',
    groupedBarTooltip: 'Сгруппированная',
    stackedBarTooltip: 'Сложенная',
    normalizedBarTooltip: '100% Сложенная',
    pieTooltip: 'Круговая',
    doughnutTooltip: 'Кольцевая',
    lineTooltip: 'Линейная',
    groupedAreaTooltip: 'Зональная',
    stackedAreaTooltip: 'Сложенная',
    normalizedAreaTooltip: '100% Сложенная',
    scatterTooltip: 'Рассеяния',
    bubbleTooltip: 'Пузырьковая',
    histogramTooltip: 'Гистограмма',
    noDataToChart: 'Нет данных для представления в виде диаграммы.',
    pivotChartRequiresPivotMode: 'Для сводной диаграммы необходимо включить режим сводной диаграммы.',
    chartSettingsToolbarTooltip: 'Меню',
    chartLinkToolbarTooltip: 'Связать с сеткой',
    chartUnlinkToolbarTooltip: 'Не связывать с сеткой',
    chartDownloadToolbarTooltip: 'Загрузить диаграмму',

    // ARIA
    ariaHidden: 'скрытый',
    ariaVisible: 'видимый',
    ariaChecked: 'проверенный',
    ariaUnchecked: 'непроверенный',
    ariaIndeterminate: 'неопределенный',
    ariaColumnSelectAll: 'Переключить на выделение всех столбцов',
    ariaInputEditor: 'Редактор ввода',
    ariaDateFilterInput: 'Ввод фильтра даты',
    ariaFilterInput: 'Ввод фильтра',
    ariaFilterColumnsInput: 'Ввод фильтра столбцов',
    ariaFilterValue: 'Значение фильтра',
    ariaFilterFromValue: 'Фильтровать от значения',
    ariaFilterToValue: 'Фильтровать до значения',
    ariaFilteringOperator: 'Оператор фильтрации',
    ariaColumnToggleVisibility: 'переключить видимость столбца',
    ariaColumnGroupToggleVisibility: 'переключить видимость группы столбцов',
    ariaRowSelect: 'Нажмите ПРОБЕЛ для выделения данной строки',
    ariaRowDeselect: 'Нажмите ПРОБЕЛ для снятия выделения данной строки',
    ariaRowToggleSelection: 'Нажмите ПРОБЕЛ, чтобы переключить выделение строки',
    ariaRowSelectAll: 'Нажмите ПРОБЕЛ, чтобы переключить выделение всех строк',
    ariaSearch: 'Поиск',
    ariaSearchFilterValues: 'Поиск значений по фильтру',
};

// eslint-disable-next-line
export const RegExpUrl = /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
