import { lazy } from 'react';

export const UnderDevelopment = lazy(() => import('./PageUnderDevelopment'));
export const MainPage = lazy(() => import('./MainPage'));
export const ProfilePage = lazy(() => import('./Profile'));

export const AdministratorPage = lazy(() => import('./Administrator'));
export const AdministratorAchievementsCard = lazy(() => import('./Administrator/Achievements'));
export const AdministratorBreedSort = lazy(() => import('./Administrator/BreedDistribution'));
export const AdministratorLinkingProfiles = lazy(() => import('./Administrator/LinkingProfiles'));
export const AdministratorPedigreeData = lazy(() => import('./Administrator/PedigreeData'));
export const AdministratorPedigreeDataCard = lazy(() => import('./Administrator/PedigreeData/FullCard'));
export const AdministratorPersonalInformation = lazy(() => import('./Administrator/PersonalInformation'));
export const AdministratorPersonalInformationCard = lazy(() => import('./Administrator/PersonalInformation/FullCard'));
export const AdministratorProfilesManagement = lazy(() => import('./Administrator/ProfilesManagement'));
export const AdministratorProfilesManagementCard = lazy(() => import('./Administrator/ProfilesManagement/FullCard'));
export const AdministratorExhibitionsMono = lazy(() => import('./Administrator/ExhibitionsMono'));
export const AdministratorExhibitionsMonoCard = lazy(() => import('./Administrator/ExhibitionsMono/FullCard'));
export const AdministratorEducationProof = lazy(() => import('./Administrator/EducationProof'));
export const AdministratorEducationProofCard = lazy(() => import('./Administrator/EducationProof/FullCard'));

export const DocumentsPage = lazy(() => import('./Documents'));
export const DocumentsAccountingRegistry = lazy(() => import('./Documents/Accounting/ApplicationsRegisters'));
export const DocumentsAccountingRegistryContribution = lazy(() => import('./Documents/Accounting/ApplicationsRegisters/Create'));
export const DocumentsAccountingRegistryCard = lazy(() => import('./Documents/Accounting/ApplicationsRegisters/FullCard'));
export const DocumentsAccountingDisplasia = lazy(() => import('./Documents/Accounting/Displasia'));
export const DocumentsAccountingDisplasiaCard = lazy(() => import('./Documents/Accounting/Displasia/FullCard'));
export const DocumentsAccountingKneeJoints = lazy(() => import('./Documents/Accounting/KneeJoints'));
export const DocumentsAccountingKneeJointsCard = lazy(() => import('./Documents/Accounting/KneeJoints/FullCard'));
export const DocumentsAccountingPriceList = lazy(() => import('./Documents/Accounting/PriceList'));
export const DocumentsAccountingPriceListCard = lazy(() => import('./Documents/Accounting/PriceList/FullCard'));
export const DocumentsAccountingDocumentsDiplomas = lazy(() => import('./Documents/Accounting/Documents/Diplomas'));
export const DocumentsAccountingDocumentsDiplomasCard = lazy(() => import('./Documents/Accounting/Documents/Diplomas/FullCard'));
export const DocumentsAccountingDocumentsFciTitles = lazy(() => import('./Documents/Accounting/Documents/FciTitles'));
export const DocumentsAccountingDocumentsFciTitlesCard = lazy(() => import('./Documents/Accounting/Documents/FciTitles/FullCard'));
export const DocumentsAccountingDocumentsKerung = lazy(() => import('./Documents/Accounting/Documents/Kerung'));
export const DocumentsAccountingDocumentsKerungCard = lazy(() => import('./Documents/Accounting/Documents/Kerung/FullCard'));
export const DocumentsAccountingDocumentsPedigreeCertificate = lazy(() => import('./Documents/Accounting/Documents/PedigreeCertificate'));
export const DocumentsAccountingDocumentsPedigreeCertificateCard = lazy(() => import('./Documents/Accounting/Documents/PedigreeCertificate/FullCard'));
export const DocumentsAccountingDocumentsWorkingCertificate = lazy(() => import('./Documents/Accounting/Documents/WorkingCertificate'));
export const DocumentsAccountingDocumentsWorkingCertificateCard = lazy(() => import('./Documents/Accounting/Documents/WorkingCertificate/FullCard'));
export const DocumentsCynologicalLitterRegistration = lazy(() => import('./Documents/CynologicalDepartment/LitterRegistration'));
export const DocumentsCynologicalLitterRegistrationCard = lazy(() => import('./Documents/CynologicalDepartment/LitterRegistration/FullCard'));
export const DocumentsCynologicalLitterRegistrationForm = lazy(() => import('./Documents/CynologicalDepartment/LitterRegistration/Registration'));
export const DocumentsCynologicalPedigreeCreating = lazy(() => import('./Documents/CynologicalDepartment/PedigreeCreating'));
export const DocumentsCynologicalPedigreeCreatingCard = lazy(() => import('./Documents/CynologicalDepartment/PedigreeCreating/FullCard'));
export const DocumentsCynologicalPedigreeCreatingSearchDog = lazy(() => import('./Documents/CynologicalDepartment/PedigreeCreating/FullCard/SearchDog'));
export const DocumentsCynologicalPedigreeCreatingSearchOwner = lazy(() => import('./Documents/CynologicalDepartment/PedigreeCreating/FullCard/SearchOwner'));
export const DocumentsCynologicalPedigreeReplacement = lazy(() => import('./Documents/CynologicalDepartment/PedigreeReplacement'));
export const DocumentsCynologicalPedigreeReplacementCard = lazy(() => import('./Documents/CynologicalDepartment/PedigreeReplacement/FullCard'));
export const DocumentsCynologicalAttachingDogsTable = lazy(() => import('./Documents/CynologicalDepartment/AttachingDogs'));
export const DocumentsCynologicalAttachingDogs = lazy(() => import('./Documents/CynologicalDepartment/AttachingDogs/FullCard'));
export const DocumentsFederationClubsDetailedReport = lazy(() => import('./Documents/Federation/ClubsDetailedReport'));
export const DocumentsFederationClubsDetailedReportReport = lazy(() => import('./Documents/Federation/ClubsDetailedReport/Report'));
export const DocumentsFederationLitterRegistration = lazy(() => import('./Documents/Federation/LitterRegistration'));
export const DocumentsFederationLitterRegistrationCard = lazy(() => import('./Documents/Federation/LitterRegistration/FullCard'));
export const DocumentsFederationLitterRegistrationOneCard = lazy(() => import('./Documents/Federation/LitterRegistration/FullCard/LitterCard'));
export const DocumentsFederationPedigreeCreating = lazy(() => import('./Documents/Federation/PedigreeCreating'));
export const DocumentsFederationPedigreeCreatingCard = lazy(() => import('./Documents/Federation/PedigreeCreating/FullCard'));
export const DocumentsFederationPedigreeCreatingOneCard = lazy(() => import('./Documents/Federation/PedigreeCreating/FullCard/PedigreeCard'));
export const DocumentsFederationPedigreeReplacement = lazy(() => import('./Documents/Federation/PedigreeReplacement'));
export const DocumentsFederationPedigreeReplacementCard = lazy(() => import('./Documents/Federation/PedigreeReplacement/FullCard'));
export const DocumentsPrintingWorkspaceCard = lazy(() => import('./Documents/PrintingWorkspace'));
export const DocumentsPrintReportsCard = lazy(() => import('./Documents/PrintingWorkspace/PrintReports'));
export const DocumentsProductionDepartmentDisplasia = lazy(() => import('./Documents/ProductionDepartment/Displasia'));
export const DocumentsProductionDepartmentDisplasiaCard = lazy(() => import('./Documents/ProductionDepartment/Displasia/FullCard'));
export const DocumentsProductionDepartmentExhibitions = lazy(() => import('./Documents/ProductionDepartment/Exhibitions'));
export const DocumentsProductionDepartmentExhibitionsCard = lazy(() => import('./Documents/ProductionDepartment/Exhibitions/FullCard'));
export const DocumentsProductionDepartmentKneeJoints = lazy(() => import('./Documents/ProductionDepartment/KneeJoints'));
export const DocumentsProductionDepartmentKneeJointsCard = lazy(() => import('./Documents/ProductionDepartment/KneeJoints/FullCard'));
export const DocumentsProductionDepartmentDocumentsDiplomas = lazy(() => import('./Documents/ProductionDepartment/Documents/Diplomas'));
export const DocumentsProductionDepartmentDocumentsDiplomasCard = lazy(() => import('./Documents/ProductionDepartment/Documents/Diplomas/FullCard'));
export const DocumentsProductionDepartmentDocumentsFciTitles = lazy(() => import('./Documents/ProductionDepartment/Documents/FciTitles'));
export const DocumentsProductionDepartmentDocumentsFciTitlesCard = lazy(() => import('./Documents/ProductionDepartment/Documents/FciTitles/FullCard'));
export const DocumentsProductionDepartmentDocumentsKerung = lazy(() => import('./Documents/ProductionDepartment/Documents/Kerung'));
export const DocumentsProductionDepartmentDocumentsKerungCard = lazy(() => import('./Documents/ProductionDepartment/Documents/Kerung/FullCard'));
export const DocumentsProductionDepartmentDocumentsPedigreeCertificate = lazy(() => import('./Documents/ProductionDepartment/Documents/PedigreeCertificate'));
export const DocumentsProductionDepartmentDocumentsPedigreeCertificateCard = lazy(() => import('./Documents/ProductionDepartment/Documents/PedigreeCertificate/FullCard'));
export const DocumentsProductionDepartmentDocumentsWorkingCertificate = lazy(() => import('./Documents/ProductionDepartment/Documents/WorkingCertificate'));
export const DocumentsProductionDepartmentDocumentsWorkingCertificateCard = lazy(() => import('./Documents/ProductionDepartment/Documents/WorkingCertificate/FullCard'));
export const DocumentsSecretariatAchievements = lazy(() => import('./Documents/Secretariat/Achievements'));
export const DocumentsSecretariatDisplasia = lazy(() => import('./Documents/Secretariat/Displasia'));
export const DocumentsSecretariatDisplasiaCard = lazy(() => import('./Documents/Secretariat/Displasia/FullCard'));
export const DocumentsSecretariatExhibitions = lazy(() => import('./Documents/Secretariat/Exhibitions'));
export const DocumentsSecretariatExhibitionsCard = lazy(() => import('./Documents/Secretariat/Exhibitions/FullCard'));
export const DocumentsSecretariatKneeJoints = lazy(() => import('./Documents/Secretariat/KneeJoints'));
export const DocumentsSecretariatKneeJointsCard = lazy(() => import('./Documents/Secretariat/KneeJoints/FullCard'));
export const DocumentsSecretariatDocumentsDiplomas = lazy(() => import('./Documents/Secretariat/Documents/Diplomas'));
export const DocumentsSecretariatDocumentsDiplomasCard = lazy(() => import('./Documents/Secretariat/Documents/Diplomas/FullCard'));
export const DocumentsSecretariatDocumentsFciTitles = lazy(() => import('./Documents/Secretariat/Documents/FciTitles'));
export const DocumentsSecretariatDocumentsFciTitlesCard = lazy(() => import('./Documents/Secretariat/Documents/FciTitles/FullCard'));
export const DocumentsSecretariatDocumentsKerung = lazy(() => import('./Documents/Secretariat/Documents/Kerung'));
export const DocumentsSecretariatDocumentsKerungCard = lazy(() => import('./Documents/Secretariat/Documents/Kerung/FullCard'));
export const DocumentsSecretariatDocumentsPedigreeCertificate = lazy(() => import('./Documents/Secretariat/Documents/PedigreeCertificate'));
export const DocumentsSecretariatDocumentsPedigreeCertificateCard = lazy(() => import('./Documents/Secretariat/Documents/PedigreeCertificate/FullCard'));
export const DocumentsSecretariatDocumentsWorkingCertificate = lazy(() => import('./Documents/Secretariat/Documents/WorkingCertificate'));
export const DocumentsSecretariatDocumentsWorkingCertificateCard = lazy(() => import('./Documents/Secretariat/Documents/WorkingCertificate/FullCard'));
export const DocumentsVeterinarianDisplasia = lazy(() => import('./Documents/Veterinarian/Displasia'));
export const DocumentsVeterinarianDisplasiaCard = lazy(() => import('./Documents/Veterinarian/Displasia/FullCard'));

export const DogsPage = lazy(() => import('./Dogs'));
export const DogsCreateDog = lazy(() => import('./Dogs/CreateDog'));
export const DogsDog = lazy(() => import('./Dogs/Dog'));
export const DogsDogApplications = lazy(() => import('./Dogs/Applications'));
export const DogsDogDocuments = lazy(() => import('./Dogs/Documents'));
// export const DogsDogDysplasia = lazy(() => import('./Dogs/Dysplasia')); // TODO удалить, если страница не будет использоваться
export const DogsDogInformation = lazy(() => import('./Dogs/Information'));
export const DogsDogLittermates = lazy(() => import('./Dogs/Littermates'));
export const DogsDogLitters = lazy(() => import('./Dogs/Litters'));
export const DogsDogPedigree = lazy(() => import('./Dogs/Pedigree'));
export const DogsDogSanctions = lazy(() => import('./Dogs/Sanctions'));

export const ReferencesPage = lazy(() => import('./References'));
export const ReferencesBreedsClassifier = lazy(() => import('./References/BreedsReference/BreedsClassifier'));
export const ReferencesBreedsClassifierCreateCard = lazy(() => import('./References/BreedsReference/BreedsClassifier/CreateAndEditCard'));
export const ReferencesBreedsClassifierCard = lazy(() => import('./References/BreedsReference/BreedsClassifier/FullCard'));
export const ReferencesBreedsGroupsFCI = lazy(() => import('./References/BreedsReference/BreedsGroupsFCI'));
export const ReferencesBreedsGroupsFCICreateCard = lazy(() => import('./References/BreedsReference/BreedsGroupsFCI/CreateAndEditCard'));
export const ReferencesBreedsGroupsFCICard = lazy(() => import('./References/BreedsReference/BreedsGroupsFCI/FullCard'));
export const ReferencesBreedsSectionsFCI = lazy(() => import('./References/BreedsReference/BreedsSectionsFCI'));
export const ReferencesBreedsSectionsFCICreateCard = lazy(() => import('./References/BreedsReference/BreedsSectionsFCI/CreateAndEditCard'));
export const ReferencesBreedsSectionsFCICard = lazy(() => import('./References/BreedsReference/BreedsSectionsFCI/FullCard'));
export const ReferencesClubs = lazy(() => import('./References/CynologicalOrganizations/Clubs'));
export const ReferencesClubsCard = lazy(() => import('./References/CynologicalOrganizations/Clubs/FullCard'));
export const ReferencesClubsCreateCard = lazy(() => import('./References/CynologicalOrganizations/Clubs/CreateAndEditCard'));
export const ReferencesFederalDistricts = lazy(() => import('./References/AddressClassifier/FederalDistricts'));
export const ReferencesFederalDistrictsCard = lazy(() => import('./References/AddressClassifier/FederalDistricts/FullCard'));
export const ReferencesFederations = lazy(() => import('./References/CynologicalOrganizations/Federations'));
export const ReferencesFederationsCard = lazy(() => import('./References/CynologicalOrganizations/Federations/FullCard'));
export const ReferencesFederationsCreateCard = lazy(() => import('./References/CynologicalOrganizations/Federations/CreateAndEditCard'));
export const ReferencesKennels = lazy(() => import('./References/CynologicalOrganizations/Kennels'));
export const ReferencesKennelsCard = lazy(() => import('./References/CynologicalOrganizations/Kennels/FullCard'));
export const ReferencesKennelsCreateCard = lazy(() => import('./References/CynologicalOrganizations/Kennels/CreateAndEditCard'));
export const ReferencesLocalities = lazy(() => import('./References/AddressClassifier/Localities'));
export const ReferencesLocalitiesCard = lazy(() => import('./References/AddressClassifier/Localities/FullCard'));
export const ReferencesLocalitiesCreateCard = lazy(() => import('./References/AddressClassifier/Localities/CreateAndEditCard'));
export const ReferencesLocalitiesTypes = lazy(() => import('./References/AddressClassifier/LocalitiesTypes'));
export const ReferencesLocalitiesTypesCard = lazy(() => import('./References/AddressClassifier/LocalitiesTypes/FullCard'));
export const ReferencesLocalitiesTypesCreateCard = lazy(() => import('./References/AddressClassifier/LocalitiesTypes/CreateAndEditCard'));
export const ReferencesNBC = lazy(() => import('./References/CynologicalOrganizations/NationalBreedClubs'));
export const ReferencesNBCCard = lazy(() => import('./References/CynologicalOrganizations/NationalBreedClubs/FullCard'));
export const ReferencesNBCCreateCard = lazy(() => import('./References/CynologicalOrganizations/NationalBreedClubs/CreateAndEditCard'));
export const ReferencesRegions = lazy(() => import('./References/AddressClassifier/Regions'));
export const ReferencesRegionsCard = lazy(() => import('./References/AddressClassifier/Regions/FullCard'));
