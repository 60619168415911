import { useQueryClient } from '@tanstack/react-query';
import { useGetNavigationCounter } from '../../../../hooks/dogQuery.hook';

import { useRequest } from '../../../../hooks/request.hook';

import { DOG_GET } from '../../../../constants/requests';
import { TIME } from '../../../../constants/time';

export const useNav = (dogId) => {
    const { data: counters, isSuccess } = useGetNavigationCounter(dogId);
    const queryClient = useQueryClient();
    const { request } = useRequest();

    if (isSuccess) {
        if (!!counters.litters) {
            queryClient.prefetchQuery({
                queryKey: [DOG_GET.GET_LITTERS_DATA, dogId],
                queryFn: async () => {
                    try {
                        const response = await request({
                            url: `/api/v1/dogs/${dogId}/litters`,
                        });
                        if (!response) {
                            throw new Error('Network error');
                        }
                        return response;
                    } catch (error) {
                        console.error('Error fetching litters data:', error);
                        return [];
                    }
                },
                gcTime: TIME.FIVE_MINUTES,
                staleTime: TIME.FIVE_MINUTES,
            });
        }
        if (!!counters.littermates) {
            queryClient.prefetchQuery({
                queryKey: [DOG_GET.GET_LITTERMATES_DATA, dogId],
                queryFn: async () => {
                    try {
                        const response = await request({
                            url: `/api/v1/dogs/${dogId}/littermates`,
                        });
                        if (!response) {
                            throw new Error('Network error');
                        }
                        return response;
                    } catch (error) {
                        console.error('Error fetching littermates data:', error);
                        return [];
                    }
                },
                gcTime: TIME.FIVE_MINUTES,
                staleTime: TIME.FIVE_MINUTES,
            });
        }
        if (!!counters.sanctions) {
            queryClient.prefetchQuery({
                queryKey: [DOG_GET.GET_SANCTIONS_DATA, dogId],
                queryFn: async () => {
                    try {
                        const response = await request({
                            url: '/api/v1/dogs/everk_dog_sanctions_dog_id',
                            params: {
                                dog_id: dogId,
                            },
                        });
                        if (!response) {
                            throw new Error('Network error');
                        }
                        return response;
                    } catch (error) {
                        console.error('Error fetching dog_sanctions data:', error);
                        return [];
                    }
                },
                gcTime: TIME.FIVE_MINUTES,
                staleTime: TIME.FIVE_MINUTES,
            });
        }
    }

    const menu = [
        {
            path: '/events',
            name: 'Мероприятия',
            icon: 'event',
        },
        {
            path: '/references',
            name: 'Справочники',
            icon: 'menu_book',
        },
        {
            path: '/documents',
            name: 'Документы',
            icon: 'description',
        },
        {
            path: '/administrator',
            name: 'Администратор',
            icon: 'admin_panel_settings',
        },
        {
            path: '/learning-center',
            name: 'Учебный центр',
            icon: 'school',
        },
        {
            path: '/rkf-online',
            name: 'RKF.Online',
            icon: 'settings',
        },
        {
            path: '/dogs',
            name: 'Собаки',
            icon: 'pets',
        },
    ];

    if (dogId && isSuccess) {
        menu[6].children = [
            {
                path: `/dogs/${dogId}`,
                name: 'Общие данные',
                blocked: false,
            },
            {
                path: `/dogs/${dogId}/pedigree`,
                name: 'Родословная',
                blocked: false,
            },
            {
                path: `/dogs/${dogId}/information`,
                name: 'Информация',
                blocked: false,
            },
            {
                path: `/dogs/${dogId}/litters`,
                name: `Помёты ${+counters.litters > 0
                    ? '(' + counters.litters + ')'
                    : ''}`,
                blocked: false,
            },
            {
                path: `/dogs/${dogId}/littermates`,
                name: `Однопомётники ${+counters.littermates > 0
                    ? '(' + counters.littermates + ')'
                    : ''}`,
                blocked: false,
            },
            {
                path: `/dogs/${dogId}/sanctions`,
                name: `Санкции ${!!counters.sanctions
                    ? +counters.sanctions.active === +counters.sanctions.total
                        ? '(' + counters.sanctions.total + ')'
                        : '(' + counters.sanctions.active + ' из ' + counters.sanctions.total + ')'
                    : ''}`,
                blocked: false,
            },
            {
                path: `/dogs/${dogId}/applications`,
                name: 'Заявки',
                blocked: false,
            },
            {
                path: `/dogs/${dogId}/documents`,
                name: 'Документы',
                blocked: false,
            },
            // удалить, если страница не будет использоваться
            // {
            //     path: `/dogs/${dogId}/dysplasia`,
            //     name: 'Дисплазия',
            //     blocked: isAccessDenied(userActions, 'HandDogDysplasia.GETAll'),
            // },
        ];
    }

    return menu;
};
