import { memo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useToggleMenu } from './hooks/toggleMenu.hook';
import { useAuth } from './hooks/auth.hook';
import { AuthContext } from './context/AuthContext';
import { MenuContext } from './context/MenuContext';

import Loading from './components/Loading';
import User from './pages/User';
import Auth from './pages/Auth';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 2,
        },
    },
});

const App = () => {
    const { token, user, login, logout, getUpdatedToken, ready } = useAuth();
    const { isOpen, toggleMenu, dogId, setDogId } = useToggleMenu();
    const isAuthenticated = !!token && !!user;

    return !ready
        ? <Loading />
        : <AuthContext.Provider value={{ token, user, login, logout, getUpdatedToken }}>
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <SnackbarProvider
                    maxSnack={7}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <Router>
                        {!isAuthenticated
                            ? <Auth />
                            : <MenuContext.Provider value={{ isOpen, toggleMenu, dogId, setDogId }}>
                                <User />
                            </MenuContext.Provider>
                        }
                    </Router>
                </SnackbarProvider>
            </QueryClientProvider>
        </AuthContext.Provider>;
};

export default memo(App);
