import { memo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Collapse, Icon, List, ListItem, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import './index.scss';

const NavItem = ({ item }) => {
    const [open, setOpen] = useState(true);
    const location = useLocation();

    return (
        !item.children
            ? <ListItem
                component="li"
                className="navigation__item"
            >
                <NavLink activeclassname="active" className="navigation__link" to={item.path}>
                    {item.icon && (
                        <Icon className="navigation__icon">
                            {item.icon}
                        </Icon>
                    )}
                    <ListItemText
                        className="navigation__text"
                        primary={item.name}
                    />
                </NavLink>
            </ListItem>
            : item.path
                ? <>
                    <ListItem
                        component="li"
                        className="navigation__item"
                    >
                        <NavLink activeclassname="active" className="navigation__link" to={item.path}>
                            {item.icon && (
                                <Icon className="navigation__icon">
                                    {item.icon}
                                </Icon>
                            )}
                            <ListItemText
                                className="navigation__text"
                                primary={item.name}
                            />
                        </NavLink>
                    </ListItem>
                    <li className="navigation__item">
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="ul" disablePadding>
                                {item.children.filter((child) => child.blocked !== true).map((child) =>
                                    <ListItem component="li" key={child.path}>
                                        <NavLink activeclassname="active" className="navigation__link" end to={child.path}>
                                            <ListItemText
                                                className="navigation__sub-text"
                                                primary={child.name}
                                            />
                                        </NavLink>
                                    </ListItem>,
                                )}
                            </List>
                        </Collapse>
                    </li>
                </>
                : <>
                    <ListItem
                        component="li"
                        className={`navigation__dropdown${open ? ' _open' : ''}`}
                        onClick={() => setOpen(!open)}
                    >
                        {item.icon && (
                            <Icon className="navigation__icon">
                                {item.icon}
                            </Icon>
                        )}
                        <ListItemText
                            className="navigation__text"
                            primary={item.name}
                        />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <li className="navigation__item">
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List component="ul" disablePadding>
                                {item.children.map((child) =>
                                    <ListItem component="li" key={child.path}>
                                        <NavLink activeclassname={child.path === location.pathname ? 'active' : ''}
                                            className="navigation__link"
                                            to={child.path}>
                                            <ListItemText
                                                className="navigation__sub-text"
                                                primary={child.name}
                                            />
                                        </NavLink>
                                    </ListItem>,
                                )}
                            </List>
                        </Collapse>
                    </li>
                </>
    );
};

export default memo(NavItem);
