import { configureStore } from '@reduxjs/toolkit';

import { userSlice } from './entities/user';

export const store = configureStore({
    reducer: {
        user: userSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActionPaths: ['payload'],
        },
    }),
});

// Определяем тип стора (например, для useSelector)
export type TRootState = ReturnType<typeof store.getState>;
// Определяем тип диспатча
export type TDispatch = typeof store.dispatch;
