import { memo, useContext } from 'react';
import { Link } from 'react-router-dom';

import { MenuContext } from '../../../../context/MenuContext';

import ScrollContainer from '../../../UI/ScrollContainer';
import imgLogo from '../../../../assets/img/rkf-logo-new.svg';
import Navigation from '../Navigation';

import './index.scss';

const Sidebar = () => {
    const { isOpen } = useContext(MenuContext);

    return (
        <div className={`user-layout__sidebar sidebar${isOpen ? ' _open' : ''}`}>
            <div className="sidebar__content">
                <div className="sidebar__head">
                    <Link to="/" className="sidebar__logo">
                        <img src={imgLogo} alt="" className="auth-layout__logo-img"/>
                        <span className="auth-layout__logo-text">РКФ Офис</span>
                    </Link>
                </div>
                <ScrollContainer
                    className="sidebar__scroll"
                >
                    <div className="sidebar__navigation">
                        <Navigation/>
                    </div>
                </ScrollContainer>
                <p className="sidebar__footer">Версия 2.0</p>
            </div>
        </div>
    );
};

export default memo(Sidebar);
