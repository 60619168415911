import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateTokenData } from '../utils/authApi';
import { addUser, clearUser } from '../redux/entities/user';

export const useAuth = () => {
    const [token, setToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null);
    const [user, setUser] = useState(null);
    const [expire, setExpire] = useState(null);
    const [ready, setReady] = useState(false);
    const dispatch = useDispatch();

    const login = useCallback((accessData) => {
        localStorage.setItem('token', JSON.stringify(accessData.access_token));
        localStorage.setItem('expire', JSON.stringify(accessData.expire));
        localStorage.setItem('refresh_token', JSON.stringify(accessData.refresh_token));
        localStorage.setItem('user', JSON.stringify(accessData.user));

        dispatch(addUser(accessData));

        setToken(accessData.access_token);
        setExpire(accessData.expire);
        setRefreshToken(accessData.refresh_token);
        setUser(accessData.user);
    }, []);

    const logout = useCallback(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('expire');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user');

        dispatch(clearUser());

        setToken(null);
        setExpire(null);
        setRefreshToken(null);
        setUser(null);
    }, []);

    const getUpdatedToken = useCallback(async () => {
        let updatedToken = '';

        await updateTokenData(refreshToken)
            .then((res) => {
                updatedToken = res.access_token;
                localStorage.setItem('token', JSON.stringify(updatedToken));
                localStorage.setItem('expire', JSON.stringify(res.expire));

                dispatch(addUser({ access_token: JSON.stringify(updatedToken), expire: JSON.stringify(res.expire) }));

                setToken(updatedToken);
                setExpire(res.expire);
            })
            .catch((err) => {
                console.error('Ошибка при обновлении токена', err);
            });

        return updatedToken;
    }, [refreshToken]);

    useEffect(() => {
        const localToken = JSON.parse(localStorage.getItem('token'));
        const localExpire = JSON.parse(localStorage.getItem('expire'));
        const localRefreshToken = JSON.parse(localStorage.getItem('refresh_token'));
        const localUser = JSON.parse(localStorage.getItem('user'));

        if (localToken && localExpire && localRefreshToken && localUser) {
            const accessData = {
                access_token: localToken,
                expire: localExpire,
                refresh_token: localRefreshToken,
                user: localUser,
            };

            login(accessData);
        }

        setReady(true);
    }, [login]);

    return { login, logout, getUpdatedToken, token, user, expire, ready };
};
