import { memo, useContext } from 'react';
import { List } from '@mui/material';
import { MenuContext } from '../../../../context/MenuContext';

import NavItem from './NavItem';

import { useNav } from './config';

import './index.scss';

const Navigation = () => {
    const { dogId } = useContext(MenuContext);

    return (
        <List
            component="ul"
            className="navigation"
        >
            {useNav(dogId).map((item, i) =>
                <NavItem item={item} key={`nav-item-${i}`} />,
            )}
        </List>
    );
};

export default memo(Navigation);
