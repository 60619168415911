import { createSlice } from '@reduxjs/toolkit';

import { getUserActions } from './thunks/getUserActions';

export const userSlice = createSlice({
    name: 'user',
    initialState: {},
    reducers: {
        addUser: (state, { payload }) => {
            return { ...state, ...payload };
        },
        clearUser: () => {
            return {};
        },
    },
    extraReducers: (builder) =>
        builder.addCase(
            getUserActions.fulfilled, (state, { payload }) => {
                return { ...state, actions: payload };
            },
        ),
});

export const { addUser, clearUser } = userSlice.actions;
