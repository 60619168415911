import { memo } from 'react';
import { CircularProgress } from '@mui/material';

import './index.scss';

const Loading = ({ fixed = true, small = false, className = '' }) => (
    <div className={`loading${fixed ? ' fixed' : ''}${small ? ' small' : ''}${className ? ` ${className}` : ''}`}>
        <CircularProgress size={small ? 20 : 40} />
    </div>
);

export const TableLoading = () => (
    <div className="loading fixed">
        <CircularProgress size={120}/>
        <span className="text">загрузка</span>
    </div>
);

export default memo(Loading);
