import { memo, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import UserLayout from '../../components/Layouts/UserLayout';
import Loading from '../../components/Loading';
import ProtectedRoute from '../../components/ProtectedRoute/ProtectedRoute';

import { userRoutes } from './routes';

const User = () => (
    <UserLayout>
        <Routes>
            {userRoutes.map((route) =>
                <Route
                    key={route.path}
                    path={route.path}
                    element={
                        <ProtectedRoute
                            isProtected={route.isProtected}
                            protectAction={route.protectAction ? route.protectAction : ''}
                            component={() =>
                                <Suspense fallback={<Loading />}>
                                    <route.element />
                                </Suspense>
                            }
                        />
                    }
                />,
            )}
            <Route
                path="*"
                element={<Navigate to="/" replace />}
            />
        </Routes>
    </UserLayout>
);

export default memo(User);
