import { memo, PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from '../../../hooks/request.hook';

import Sidebar from './Sidebar';
import Header from './Header';

import { addUser } from '../../../redux/entities/user';
import { getUserActions } from '../../../redux/entities/user/thunks/getUserActions';
import { selectUserExpireAccessToken, selectUserRefreshToken } from '../../../redux/selectors/mainSelectors';

import type { TDispatch } from '../../../redux';

import './index.scss';

const UserLayout = ({ children }: PropsWithChildren) => {
    const expire = useSelector(selectUserExpireAccessToken);
    const refreshToken = useSelector(selectUserRefreshToken);
    const dispatch = useDispatch<TDispatch>();
    const { request } = useRequest();

    useEffect(() => {
        // Если протух токен, то получаем новый и права
        if (expire && Date.now() >= +expire * 1000) {
            request({
                url: `/api/v1/refresh_token?refresh_token=${refreshToken}`,
                method: 'POST',
            }).then((data) => {
                if (data) {
                    localStorage.setItem('token', JSON.stringify(data.access_token));
                    localStorage.setItem('expire', JSON.stringify(data.expire));

                    dispatch(addUser({
                        access_token: JSON.stringify(data.access_token),
                        expire: JSON.stringify(data.expire),
                    }));
                }
            }).then(() => {
                dispatch(getUserActions());
            });
        } else {
            dispatch(getUserActions());
        }
    }, []);

    return (
        <div className="user-layout">
            <Sidebar />
            <div className="user-layout__main">
                <Header />
                <div className="user-layout__content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default memo(UserLayout);
