import { useCallback, useContext, useState } from 'react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useSnackbar } from 'notistack';
import { AuthContext } from '../context/AuthContext';

export interface RequestOptions extends AxiosRequestConfig {
    isMultipart?: boolean;
}

type SuccessCallback = (data: any) => void;
type ErrorCallback = (error: AxiosError | unknown) => void;
type Headers = Record<string, string>;

export const getHeaders = (token: string | null, isMultipart = false) => {
    let headers: Headers = {};

    if (token) headers['Authorization'] = 'Bearer ' + token;

    headers['Accept'] = 'application/json';
    headers['Cache-Control'] = 'no-cache';
    headers['Content-Type'] = isMultipart ? 'multipart/form-data' : 'application/json, text/plain, */*';

    return headers;
};

export const useRequest = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);
    const { logout, getUpdatedToken } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();

    const clearError = useCallback(() => setErrors([]), []);

    const request = useCallback(
        async (
            options: RequestOptions,
            onSuccess?: SuccessCallback,
            onError?: ErrorCallback,
        ) => {
            setLoading(true);

            try {
                const refreshToken = localStorage.getItem('refresh_token')
                    ? JSON.parse(localStorage.getItem('refresh_token')!)
                    : '';
                const expire = localStorage.getItem('expire')
                    ? JSON.parse(localStorage.getItem('expire')!)
                    : '';
                let token = localStorage.getItem('token')
                    ? JSON.parse(localStorage.getItem('token')!)
                    : '';

                if (refreshToken && +expire) {
                    if (Date.now() >= +expire * 1000) {
                        token = await getUpdatedToken(refreshToken);
                    }
                }

                const axiosConfig = {
                    ...options,
                    headers: getHeaders(token, options.isMultipart),
                };

                const response = await axios(axiosConfig);

                setLoading(false);

                if (response.status === 210) {
                    enqueueSnackbar(`Внимание: ${response.data.detail.map((error: any) => (
                        error.loc
                            ? `Внимание: ${error.loc[error.loc.length - 1]}: ${error.msg}`
                            : error.msg
                    ))}`, { variant: 'warning' });
                }

                if (onSuccess) {
                    return onSuccess(response.data);
                } else {
                    return response.data;
                }
            } catch (e: any) {
                setLoading(false);

                if (e.response) {
                    if (e.response.status === 401) logout();
                    if (onError) onError(e);
                    if (e.isAxiosError) {
                        if (e.response.status === 403) {
                            setErrors(['Доступ запрещен']);
                            enqueueSnackbar('Доступ запрещен', { variant: 'error' });
                        } else if (e.response.data.detail && Array.isArray(e.response.data.detail)) {
                            const errors = e.response.data.detail.map((error: any) => (
                                error.loc
                                    ? `Ошибка ${error.loc[error.loc.length - 1]}: ${error.msg}`
                                    : error.msg
                            ));

                            setErrors(errors);
                            errors.forEach((error: any) => enqueueSnackbar(
                                error instanceof Object ? Object.values(error).join('; ') : error, { variant: 'error' },
                            ));
                        } else {
                            setErrors([`Ошибка ${e.response.status}: ${e.response.statusText}`]);
                            enqueueSnackbar(`Ошибка ${e.response.status}: ${e.response.statusText}`, { variant: 'error' });
                        }
                    }
                } else {
                    setErrors(['Что-то пошло не так...']);
                    enqueueSnackbar('Что-то пошло не так...', { variant: 'error' });
                }
            }
        }, [logout, enqueueSnackbar, getUpdatedToken]);

    return { loading, errors, request, clearError };
};
